@if $toc {
  /* - Order tracking___________Order tracking */
} @else {
  /* Order tracking [component]
    ========================================================================== */

  // Root element
  .c-order-tracking {
    font-size: rem(14); // 14px
    line-height: lh(24, 14); // 24px

    @include mq($until: S) {
      margin-bottom: rem(16); // 16px
    }

    @include mq($from: S) {
      margin: rem(16) 0 rem(32); // 16px 0 32px 0
    }
  }


  // Delivery date
  .c-order-tracking__delivery-date {
    @include mq($until: S) {
      display: block;
      margin-bottom: rem(8); // 8px
    }

    @include mq($from: S) {
      position: relative;
      margin-right: rem(16); // 16px
      padding-left: rem(12); // 12px

      &::before {
        content: "";
        position: absolute;
        top: 50%;
        left: 2px;
        width: rem(4); // 4px
        height: 1px;
        background-color: theme(default-txt);
      }
    }
  }
}
