@if $toc {
  /* - Counter__________________Counter (shopping tunnel steps) */
} @else {
  /* Counter (shopping tunnel steps) [component]
   ========================================================================== */
  .c-counter {
    counter-increment: step; // Increment the value of step counter by 1
    position: relative; // Usefull to c-counter__link
    display: block;
    padding: rem(18) rem(32); // 18px 32px
    font-size: rem(14); // 14px
    line-height: lh(18, 14); // 18px
    font-family: $defaut-font-family;
    font-weight: 700;
    text-transform: uppercase;
    color: theme(brand);
    border: 1px solid theme(default-bdc);
    background-color: theme(default-bgc);

    @include mq($until: S) {
      padding: rem(11) rem(16); // 11px 16px
    }

    &::before {   /* stylelint-disable-line */
      content: counter(step) ". ";
    }

    &:not(:first-child) {
      margin-top: rem(16); // 16px
    }
  }


  // Link in title
  .c-counter__link {
    @include font($type: regular, $family: $sans-serif);
    position: absolute;
    top: rem(12); // 12px
    right: rem(16); // 16px
    font-size: rem(12); // 12px
    line-height: lh(18, 12); // 18px
    text-transform: none;

    @include mq($from: S) {
      font-size: rem(14); // 14px
      line-height: lh(24, 14); // 21px
    }
  }
}
