@if $toc {
  /* - Relay point______________Relay point selection */
} @else {
  /* Relay point selection [component]
    ========================================================================== */

  // Root element
  .c-relay-point {
    @include mq($from: M) {
      margin-top: rem(24); // 24px
      margin-right: rem(-8); // Space required for the custom scrollbar
      margin-left: rem(-28); // Padding-left of .c-form__block--radio
    }
  }


  // Relay point list
  .c-relay-point__list {
    border-top: 1px solid theme(default-bdc);

    @include mq($from: M) {
      position: absolute;
      top: 0;
      right: rem(20); // 20px
      bottom: 0;
      left: 0;
    }
  }


  // List item
  .c-relay-point__item {
    padding: rem(16) 0; // 16px 0
    border-bottom: 1px solid theme(default-bdc);

    @include mq($until: S) {
      padding-right: rem(16); // 16px
      padding-left: rem(16); // 16px
    }
  }

  .c-relay-point__item {
    .c-address {
      margin-right: rem(24); // 24px
    }

    .c-btn {
      margin-top: rem(8); // 8px
      text-align: center;

      &:focus {
        color: theme(btn--secondary-txt_hover);
        background-color: theme(btn--secondary-bgc_hover);
        border-color: theme(btn--secondary-bdc_hover);
      }

      @include mq($until: S) {
        display: block;
        margin-top: rem(16); // 16px
      }
    }
  }

  // Opening Hour
  // List
  [class].c-relay-point__oh-list {
    margin-top: rem(8); // 8px

    @include mq($from: S) {
      font-size: rem(14); // 14px
      line-height: lh(21, 14); // 21px
    }
  }

  // Days
  .c-relay-point__oh-day {
    display: inline-block;
    width: rem(120); // 120px
  }

  // Hours
  .c-relay-point__oh-time {
    font-weight: 600;
  }



  // Map wrapper
  @include mq($from: S, $until: M) {
    .c-relay-point__map-wrapper {
      position: relative;

      &::before {
        content: "";
        display: block;
        padding-top: 110%; // Give a ratio of 1.10
      }
    }
  }

  @include mq($from: M) {
    .c-relay-point__map-wrapper {
      height: 100%;
    }
  }


  // Map
  .c-relay-point__map {   /* stylelint-disable-line */
    width: 100%;

    @include mq($until: S) {
      $height: 48 + 24 + 56 + 80; // 208px
      /* 208px =
        .right-action-modal__wrapper's padding-top
        + .right-action-modal-title's height
        + .c-tablist's height & margins
        + .right-action-modal__btn's height & padding
      */
      height: calc(100vh - #{rem($height)}); // 100vh - 208px
    }

    @include mq($from: S, $until: M) {
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
    }

    @include mq($from: M) {
      height: inherit;
    }
  }
}
