@if $toc {
  /* - Address__________________Address block */
} @else {
  /* Address block [component]
   ========================================================================== */

  .c-address {
    font-weight: normal;
    font-size: rem(14); // 14px
    line-height: lh(21, 14); // 21px
    text-align: left;
  }

  // Address label
  .c-address__label {
    display: block;
    margin-bottom: rem(8); // 8px
    color: theme(brand);
    font-weight: 700;
    text-transform: uppercase;
  }

  // Address name
  .c-address__name {
    display: block;
    margin-bottom: rem(8); // 8px
    color: theme(default-txt);
    font-weight: 600;
  }

  // Specific case
  // With border
  .c-address--box {
    margin-top: rem(8); // 8px
    padding: rem(12) rem(16) rem(8); // 12px 16px 8px
    border: 1px solid theme(default-bdc);

    @include mq($from: S) {
      $width: 3 * $grid-col + 2 * $grid-gutter;
      width: rem($width); // 342px
      margin-top: rem(16); // 16px
    }
  }

  // 2 addresses are displayed
  .o-layout__item.c-address--box {
    @include mq($from: S) {
      $gutter: calc($grid-gutter / 2); // = 12px => 24 / 2
      max-width: calc(50% - #{rem($gutter)});

      &:first-child {
        margin-right: rem(16); // 16px
      }
    }

    @include mq($from: M) {
      $gutter: calc(
        ($grid-gutter + 28) / 2
      ); // = 26px => 24 (half gutter) + 28 (margin-left of .c-form__delivery-address)
      max-width: calc(50% - #{rem($gutter)});
    }
  }

  // Specific case
  // In gray
  .c-address--light {
    border: none;
    padding: 0;
  }

  // Gift cards grid
  .c-gift-cards {
    gap: 2rem;
  }
}
