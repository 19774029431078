@if $toc {
  /* - Card_____________________Product card */
} @else {
  /* Product card [component]
    ========================================================================== */

  /* autoprefixer grid: autoplace */

  // Container
  .c-card {
    position: relative;

    // Override the default font color (reset to default text color)
    a {
      color: theme(default-txt);
      font-weight: normal;
    }
  }

  // Grid
  .c-card__link {
    display: grid;
    grid-template-rows: auto 1fr;
    grid-template-areas:
      'thumbnail'
      'content';
  }

  // Grid areas
  .c-card__thumbnail {
    grid-area: thumbnail;
  }

  .c-card__content {
    grid-area: content;
  }

  // Link
  .c-card__link {
    // Reset link styles
    color: inherit;
    font-weight: inherit;

    #{$interactions} {
      text-decoration: none;
      color: inherit;
    }
  }

  // Content
  [class].c-card__content {
    position: relative; // Usefull to ::before
    margin-bottom: 0;
    padding: rem(8) rem(16) rem(16); // 8px 16px 16px
    text-align: center;

    @include mq($from: S) {
      padding-top: rem(40); // 40px
    }

    // Context-specific rule
    // Display of 2 items side by side on mobile
    @include mq($until: S) {
      .o-layout-wrap &,
      .c-featured-product & {
        line-height: lh(21, 16); // 21px
      }
    }
  }

  // Hover state
  @include mq($from: S) {
    .c-card__link {
      #{$interactions} {
        .c-card__content::before {
          content: '';
          position: absolute;
          left: 0;
          right: 0;
          top: rem(22); //22px
          height: 4px;
          background-color: theme(brand);
        }
      }
    }
  }

  // Name
  .c-card__name {
    display: block;
    margin-bottom: rem(2); // 2px
    font-size: rem(14); // 14px
    line-height: lh(24, 14); // 24px

    @include mq($from: S) {
      margin-bottom: rem(8); // 8px
    }

    // Context-specific rule
    // Display of 2 items side by side on mobile
    @include mq($until: S) {
      .o-layout-wrap &,
      .c-featured-product & {
        margin-bottom: rem(8); // 8px
      }
    }
  }

  // Promotions
  .c-card__price {
    font-size: rem(18); // 18px
    line-height: lh(24, 18); // 24px
  }

  // Tags
  .c-tag--mark {
    // Context-specific rule
    // Display of 2 items side by side on mobile
    @include mq($until: S) {
      .o-layout-wrap &,
      .c-featured-product & {
        margin-top: rem(8); // 8px
      }
    }

    // Opposite slider display
    @include mq($from: S) {
      .c-slider--opposite & {
        margin-top: rem(8); // 8px
      }
    }
  }

  // Measurement
  .c-card__measurement {
    @include font($type: regular, $family: $defaut-font-family);
    margin-top: rem(8); // 8px
    margin-bottom: rem(8); // 8px
    font-size: rem(14); // 16px
    line-height: lh(24, 14); // 24px
    text-align: center;
  }

  // Thumbnail
  .c-card__thumbnail {
    position: relative;
    background-color: theme(default-bgc);

    &::before {
      content: '';
      float: left;
      padding-top: 100%; // Gives a ratio of 1
    }
  }

  // Preview
  .c-card__preview {
    img {
      position: absolute;
      display: inline-block;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;

      width: 100%;
      transition: opacity 0.5s ease-in-out;
    }

    .img-hover img {
      opacity: 0;
    }

    &:hover,
    &:focus {
      .img-main img {
        opacity: 0;
      }
      .img-hover img {
        opacity: 1;
      }
    }
  }
}
