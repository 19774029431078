@if $toc {
  /* - Message list_____________Message list */
} @else {
  /* Message list [component]
    ========================================================================== */

  // Root element
  [class].c-message-list {
    @include mq($until: S) {
      margin-bottom: rem(48); // 48px
    }

    @include mq($from: S) {
      margin-bottom: rem(96); // 96px
      border-bottom: 1px solid theme(default-bdc);
    }
  }


  // List item
  .c-message-list__item {
    position: relative; // Usefull to .c-message-list__btn
    border-top: 1px solid theme(default-bdc);
    font-size: rem(14); // 14px
    line-height: lh(24, 14); // 24px

    @include mq($until: S) {
      padding: rem(16) 0; // 16px
    }

    @include mq($from: S) {
      padding: rem(24) rem(176) rem(24) 0; // 24px 176px 24px 0
    }
  }


  // Message date
  .c-message-list__date {
    display: block;
    margin-bottom: rem(16); // 16px
    font-size: rem(12); // 12px
    line-height: lh(24, 12); // 24px
  }


  // Message subject
  .c-message-list__subject {
    display: block;
    margin-top: rem(8); // 8px

    @include mq($until: S) {
      margin-bottom: rem(16); // 16px
    }
  }


  // See detail button
  .c-message-list__btn {
    @include mq($from: S) {
      position: absolute;
      top: 50%;
      margin-top: rem(-24); // -24px : button half height
      right: 0;
    }
  }
}

