@if $toc {
  /* - Newlsetter_______________Newsletter block */
} @else {
  /* Newsletter block [component]
    ========================================================================== */

  .c-newsletter {
    margin-bottom: rem(24); // 24px
    padding: rem(24) rem(16); // 24px 16px 24px 16px
    background-color: theme(newsletter-bgc);

    @include mq($from: M) {
      margin-bottom: rem(32); // 32px
      padding: rem(40) rem(98); // 40px 98px 40px 98px
    }

    @include mq($from: L) {
      padding-right: rem(122); // 122px
      padding-left: rem(122); // 122px
    }
  }


  // Content
  .c-newsletter__content .c-h3 {
    display: block;

    @include mq($until: S) {
      margin-bottom: rem(8); // 8px
    }
  }

  // Explanation
  .c-newsletter__explanation {
    font-size: rem(14); // 14px
    line-height: lh(21, 14); // 21px
  }


  // Social networks
  [class].c-newsletter__social {
    margin-top: rem(16); // 16px
    margin-bottom: rem(16); // 16px

    @include mq($from: S) {
      margin-bottom: 0;
    }
  }

  .c-newsletter__social-item:not(:last-child) {
    margin-right: rem(16); // 16px
  }

  .c-newsletter__social-link {
    color: theme(newsletter__social-link-txt);
    background-color: theme(newsletter__social-link-bgc);
    border: 1px solid theme(newsletter__social-link-bdc);
    border-radius: 50%;
    transition: all var(--transition-duration);

    #{$interactions} {
      color: theme(newsletter__social-link-txt_hover);
      background-color: theme(newsletter__social-link-bgc_hover);
    }

    &:focus {
      outline: 1px dotted theme(default-outline);
    }
  }

  // Grid
  .c-newsletter__form-wrapper {
    margin-bottom: rem(16); // 16px

    @include mq($until: M) {
      text-align: right;
    }

    @include mq($from: M) {
      display: grid;
      grid-template-areas: "field button";
      grid-template-columns: 1fr auto;
    }
  }

  // Grid areas
  .c-newsletter__form-field {
    text-align: left;

    @include mq($until: M) {
      width: 100%;
    }

    @include mq($from: M) {
      grid-area: field;
    }
  }

  .c-newsletter__form-btn {
    text-align: center;

    @include mq($until: M) {
      margin-top: rem(8); // 8px
    }

    @include mq($from: M) {
      grid-area: button;
      margin-top: rem(25); // label's height + margin-bottom
    }
  }

  // Privacy
  .c-contextual-information {
    margin-top: rem(16); // 16px
    color: theme(default-txt);

    a {
      color: inherit;
      font-weight: 700;
    }
  }
}
