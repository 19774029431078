/* Fonts [mixin and functions]
  ========================================================================== */

/**
 * Applies font with a mixin
 * @include font($type, $family);
 *
 * | Parameter   | Type   | Value   | Description         |
 * |-------------|--------|---------|---------------------|
 * |`$type`      | string | regular | variant of the font |
 * |`$family`    | string | regular | family of the font  |
 *
 * .usage {
 *   @include font($type: regular, $family: $defaut-font-family);
 * }
 * .usage {
 *   @include font($type: bold, $family: $defaut-font-family);
 * }
 */

@mixin font($type: regular, $family: $defaut-font-family) {
  font-family: #{$family};

  @if $type == regular {
    font-weight: 400;
    font-style: normal;
  }
  @if $type == medium {
    font-weight: 500;
    font-style: normal;
  }
  @if $type == semibold {
    font-weight: 600;
    font-style: normal;
  }
  @if $type == bold {
    font-weight: 700;
    font-style: normal;
  }
}

@function em($target, $context) {
  @if (type-of($target) != number) {
    @error "`#{$target}` needs to be a number.";
  }

  @if (type-of($context) != number) {
    @error "`#{$context}` needs to be a number.";
  }

  @if $target == 0 {
    @return 0;
  }
  @return #{calc($target / $context)}em;
}

@function rem($target, $root: 16) {
  @if (type-of($target) != number) {
    @error "`#{$target}` needs to be a number.";
  }

  @if (type-of($root) != number) {
    @error "`#{$root}` needs to be a number.";
  }

  @if $target == 0 {
    @return 0;
  }
  @return #{calc($target / $root)}rem;
}

@function lh($target, $context) {
  @if (type-of($target) != number) {
    @error "`#{$target}` needs to be a number.";
  }

  @if (type-of($context) != number) {
    @error "`#{$context}` needs to be a number.";
  }

  @if $target == 0 {
    @return 0;
  }
  @return #{decimals-round(calc($target / $context))};
}
