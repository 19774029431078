@if $toc {
  /* - Identity_________________Client name & log out button */
} @else {
  /* Client name & log out button [component]
    ========================================================================== */

  .c-identity {
    position: relative; // Usefull to logout button
    padding: rem(16); // 16px
    background-color: theme(identity-bgc);
    font-size: rem(14); // 14px
    line-height: lh(21, 14); // 21px
    font-weight: 700;

    @include mq($from: S) {
      padding: rem(32); // 24px
    }
  }

  // Customer name
  .c-identity__customer {
    text-transform: uppercase;

    @include mq($until: M) {
      display: block;
      margin-bottom: rem(8); // 8px
    }
  }

  // Log out button
  .c-identity__logout {
    font-weight: normal;

    @include mq($from: S) {
      position: absolute;
      top: rem(32); // 32px
      right: rem(32); // 32px
    }
  }
}
