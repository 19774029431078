@if $toc {
  /* - Notice___________________Notice and description */
} @else {
  /* Notice and description [component]
    ========================================================================== */

  // Wrapper
  .c-notice {
    padding-bottom: rem(48); // 48px
    background-color: theme(notice-bgc);

    @include mq($until: S) {
      overflow: hidden; // Avoid margin collapse
      padding: 0 rem(16); //16px
    }
  }

  // Content
  .c-notice__content {
    @include mq($until: S) {
      background-color: theme(notice-bgc);
    }

    @include mq($from: S) {
      position: relative; // Necessary for button positioning
      margin-top: rem(128); // 128px
      margin-left: rem($grid-gutter); // 24px
      //margin-bottom: rem(48); // 48px
      padding: rem(24) rem($grid-gutter) rem(8); // 24px 24px 8px 24px
      background-color: theme(default-bgc);
    }

    @include mq($from: M) {
      $width: calc(($grid-gutter + $grid-col) / 2); // 61px
      margin-left: rem($width);
      padding: rem(48) rem(40) rem(32); // 48px 40px 32px 40px
    }

    @include mq($from: L) {
      $width: $grid-gutter + $grid-col; // 122px
      margin-left: rem($width);
    }
  }

  // Inner text wrapper
  .c-notice__inner-wrapper {
    @include mq($until: S) {
      padding: rem(16) rem(16) rem(4); // 16px 16px 16px 4px 16px
      background-color: theme(default-bgc);
    }
  }

  // Guide
  .c-notice__guide {
    @include font($type: medium, $family: $serif);
    @extend %c-h4;
    position: relative;
    display: block;
    margin-right: auto;
    margin-left: auto;
    color: theme(default-txt);
    background-color: theme(default-bgc);

    // Illustration
    // Separator
    &::before,
    &::after {
      content: '';
      display: block;
    }

    // Illustration
    &::before {
      margin: 0 auto rem(16); // 0 auto 16px auto
      width: rem(120); // 120px
      height: rem(120); // 120px
      background: url('../../../../svg/icon/confirmation.svg')
        50%
        50% /
        cover
        no-repeat;

      @include mq($until: S) {
        position: absolute;
        top: rem(24); // 24px
        left: rem(16); // 16px
      }
    }

    // Separator
    &::after {
      margin-top: rem(16); // 16px
      height: 4px;
      width: rem(40); // 40px
      background-color: theme(brand);
    }
  }

  .c-notice__guide {
    @include mq($until: S) {
      margin: rem(24) 0; // 24px 0
      width: 100%;
      padding: rem(24) rem(24) 0 rem(168); // 24px 24px 0 168px
      min-height: rem(
        168
      ); // 168px = 24 + 120 + 24 = vertical margin + image's height
    }

    @include mq($from: S) {
      margin-top: rem(128); // 128px
      margin-right: rem($grid-gutter); // 24px
      width: rem(240); // 240px
      padding: rem(24) rem(32); // 24px 32px
    }

    @include mq($from: M) {
      $width: calc(($grid-gutter + $grid-col) / 2); // 61px
      margin-right: rem($width);
    }

    @include mq($from: L) {
      $width: $grid-gutter + $grid-col; // 122px
      margin-right: rem($width);
    }

    #{$interactions} {
      color: inherit;
    }
  }

  // Action button
  @include mq($from: S) {
    .c-notice__btn {
      position: absolute;
      top: rem(-64); // -64px
      left: 0;
      width: auto;

      &:not(:first-of-type) {
        $width: $grid-gutter + $grid-col;
        left: rem($width);
      }

      // Current page
      &[aria-expanded='true']::after {
        content: '';
        position: absolute;
        top: auto;
        right: rem(-2); // -2px
        bottom: rem(-8); // -8px
        left: rem(-2); // -2px
        width: auto;
        height: 1px;
        background-color: currentColor;
        transform: none;
      }
    }
  }

  @include mq($from: M) {
    .c-notice__btn {
      &:not(:first-of-type) {
        $width: $grid-gutter + $grid-col;
        left: rem($width);
      }
    }
  }

  @include mq($from: L) {
    .c-notice__btn {
      &:not(:first-of-type) {
        $width: $grid-gutter + $grid-col;
        left: rem($width);
      }
    }
  }
}
