@if $toc {
  /* - Added to cart____________Product added to cart */
} @else {
  /* Product added to cart [component]
    ========================================================================== */

  // Product : name + preview
  .c-added-to-cart__product {
    padding: rem(16) 0; // 16px 0
    border-top: 1px solid theme(default-bdc);
    border-bottom: 1px solid theme(default-bdc);

    @include mq($until: S) {
      display: flex;
      flex-direction: column-reverse;
      align-items: center;
      text-align: center;
      padding-right: rem(16); // 16px
      padding-left: rem(16); // 16px
    }

    @include mq($from: S) {
      padding: rem(48) 0; // 48px 0
      display: table;
      width: 100%;
      direction: rtl;
    }
  }


  // Product details
  .c-added-to-cart__item {
    margin-bottom: rem(8); // 8px

    @include mq($from: S) {
      display: table-cell;
      vertical-align: middle;
      direction: ltr;
    }
  }


  // Product name
  .c-added-to-cart__name {
    display: block;
    margin-bottom: rem(8); // 8px
    font-size: rem(14); // 14px
    line-height: lh(21, 14); // 21px
    font-weight: 400;

    @include mq($from: S) {
      margin: 0 rem(16) rem(16) 0; // 0 16px 16px 0
      font-weight: 700;
      text-transform: uppercase;
    }
  }


  // Thumbnail
  .c-added-to-cart__thumbnail {
    width: rem(96); // 96px
    vertical-align: middle;

    @include mq($from: S) {
      display: table-cell;
      direction: ltr;
      width: rem(160); // 160px
    }
  }


  // Preview
  .c-added-to-cart__preview {
    @include mq($until: S) {
      margin-bottom: rem(8); // 8px
    }
    @include mq($from: S) {
      float: left;
      margin-left: rem(16); // 16px
    }
  }


  // Gift wrap option / go back / view cart
  .c-added-to-cart__action {
    text-align: center;
  }

  .c-added-to-cart__gift {
    max-width: rem(264); // 264px
    margin: rem(16) auto 0; // 16px auto 0 auto
    padding: rem(8); // 8px
    background-color: theme(added-to-cart__gift-bgc);

    @include mq($from: S) {
      max-width: rem(240); // 240px
    }
  }
}
