@if $toc {
  /* - Contextual information___Contextual information in footer & modal */
} @else {
  /* Contextual information  in footer & modal [component]
    ========================================================================== */

  .c-contextual-information {
    display: block;
    font-size: rem(14); // 14px
    line-height: lh(21, 14); // 21px
  }

  .c-contextual-information {
    .right-action-modal & {
      margin-top: rem(-12); // -12px
      margin-bottom: rem(16); // 16px
      font-size: rem(12); // 12px
      line-height: lh(21, 12); // 21px
      color: theme(contextual-information-txt);
    }
  }
}
