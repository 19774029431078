@if $toc {
  /* - Product information______Product information */
} @else {
  /* Product information [component]
    ========================================================================== */

  .c-product-information {
    @include mq($until: S) {
      margin-top: rem(20); // 20px
      padding-right: rem(16); // 16px
      padding-left: rem(16); // 16px
    }

    @include mq($from: S) {
      font-size: rem(14); // 14px
      line-height: lh(24, 14); // 24px
    }

    @include mq($from: M) {
      padding-right: rem(32); // 32px
      padding-left: rem(32); // 32px
    }

    @include mq($from: L) {
      padding-right: rem(64); // 64px
      padding-left: rem(64); // 64px
    }
  }

  // Wrapper
  [class].c-product-information__wrapper {
    padding-bottom: rem(16); // 16px
    border-bottom: 1px solid theme(default-bdc);
    margin-bottom: rem(16); // 16px

    @include mq($from: S) {
      display: block;
    }
  }

  // Product title
  .c-product-information__title {
    @extend %c-h3;

    // Separator
    &::before {
      content: '';
      display: block;
      margin-bottom: rem(32); // 32px
      height: 4px;
      width: rem(40); // 40px
      background-color: theme(brand);
    }
  }

  // Product number
  .c-product-information__reference {
    margin-bottom: rem(16); // 16px
    font-size: rem(12); // 12px
    color: theme(product-information__reference);

    @include mq($from: S) {
      margin-bottom: rem(24); // 24px
    }
  }

  // Delivery date
  .c-product-information__delivery {
    margin-bottom: rem(24); // 24px
  }

  #alma-widget {
    margin-top: 1rem;
    border: 1px solid black;
  }
}
