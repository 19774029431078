@if $toc {
  /* - FAQ______________________Frequently Asked Questions */
} @else {
  /* Frequently Asked Questions [component]
    ========================================================================== */

  // Disclosure trigger is defined in `_components.disclosure.scss` file

  // Main tag
  .c-faq {
    @include mq($until: S) {
      position: relative; // Usefull to .c-faq__wrapper
    }
  }


  // Theme wrapper
  .c-faq__wrapper {
    background-color: theme(default-bgc);
  }


  // Definition list
  // Term
  .c-faq__term {
    border: 1px solid theme(default-bdc);
    border-bottom: 0;

    &:not(:first-child) {
      margin-top: rem(8); // 8px
    }
  }


  // Definition
  .c-faq__definition {
    padding: 0 rem(16) rem(16); // 0 16px 16px 16px
    border: 1px solid theme(default-bdc);
    border-top: 0;
    font-size: rem(14); // 14px
    line-height: lh(24, 14); // 24px

    @include mq($from: S) {
      margin-top: rem(-6); // -6px
      padding: 0 rem(32) rem(24); // 0 32px 24px 32px
    }
  }

  .c-faq__definition > * + * {
    margin-top: rem(8); // 8px
  }


  // Navigation
  // Nav list
  /*[class].c-faq__nav {
    margin-bottom: rem(16); // 16px
    padding: rem(16); // 16px
    background-color: theme(faq-nav-bgc);

    @include mq($from: S) {
      margin-bottom: rem(32); // 32px
    }c-contact__teaser
  }


  // Nav item
  [class].c-faq__nav-item:not(:last-child) {
    margin-bottom: rem(24); // 24px
  }


  // Nav buttons
  .c-faq__nav-btn {
    @extend %c-h4;
    color: theme(brand);
    letter-spacing: 1.5px;

    @include mq($from: S) {
      color: theme(faq-nav__btn-txt);
    }
  }

  // Hides the opening/closing indicator arrow
  [class].c-faq__nav-btn {
    &::after {
      content: normal;
    }

    // Active theme
    &[aria-expanded="true"] {
      color: theme(brand);
    }
  }*/
}
