@if $toc {
  /* - Filter___________________Product filter */
} @else {
  /* Product filter [component]
    ========================================================================== */

  // Disclosure trigger is defined in `_components.disclosure.scss` file

  // Fieldset
  .c-filter {
    padding-right: rem(32); // 20px: minimum scrollbar space
    padding-left: rem(32); // 20px: minimum scrollbar space

    @include mq($from: S) {
      [id='filter']:first-child & {
        margin-top: rem(12); // 12px
      }
    }
  }

  // Title
  .c-filter__title {
    @extend %c-h4;

    @include mq($until: S) {
      display: none;
    }
  }

  // Fieldset
  .c-filter__category {
    padding-bottom: rem(16); // 16px
  }

  // Legend
  .c-filter__legend {
    margin-bottom: rem(16); // 16px
    font-size: rem(12); // 12px
    line-height: lh(24, 12); // 24px
    border-bottom: 1px solid theme(default-bdc);
  }

  /* Number of active filters (number)
  .c-filter__selection {
    display: inline-block;
    min-width: rem(24); // 24px
    margin-left: rem(8); // 8px
    color: theme(filter__selection-txt);
    font-size: rem(14); // 14px
    line-height: lh(24, 14); // 24px
    text-align: center;
    background-color: theme(filter__selection-bgc);
    border-radius: 50%;
  }


  // Number of active filters (text)
  .c-filter__detail {
    display: block;
    margin: rem(-6) 0 rem(16); // -6px 0 16px
    font-size: rem(14); // 14px
    line-height: lh(24, 14); // 24px

    @include mq($from: S) {
      font-size: rem(12); // 12px
      line-height: lh(24, 12); // 24px
    }
  }


  // Checkbox list
  .c-filter__list {
    padding-bottom: rem(16); // 20px
  }*/
}
