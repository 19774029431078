@if $toc {
  /* - Label____________________Label for checkboxes & radio buttons */
} @else {
  /* Label for checkboxes & radio buttons [component]
    ========================================================================== */

  // Common rules
  .c-label {
    position: relative;
    font-size: rem(14); // 14px
    line-height: lh(21, 14);

    span {
      color: inherit;

      &::before {
        content: normal;
      }
    }
  }

  // Label for checkboxes and radio buttons
  .c-label--checkbox,
  .c-label--radio {
    display: block;
    margin-bottom: rem(8); // 8px
    padding-left: rem(28); // 28px

    &:last-child {
      margin-bottom: 0;
    }

    &::before {
      content: '';
      position: absolute;
      top: 0.1rem;
      left: 0;
      width: 1rem;
      height: 1rem;
      background-color: theme(default-bgc);
      border: 1px solid theme(brand);
    }

    &::after {
      top: 0.35rem;
      left: 0.25rem;
      width: 0.5rem;
      height: 0.5rem;
      background-color: theme(brand);
    }
  }

  .c-label--highlight {
    font-weight: 600;
  }

  .c-label--tighten {
    line-height: lh(20, 14);
  }

  .c-label--uppercase {
    font-weight: 600;
    text-transform: uppercase;

    @include mq($until: S) {
      font-size: rem(12); // 12px
      line-height: lh(21, 12); // 21px
    }
  }

  // Radio buttons
  .c-label--radio {
    &::before,
    &::after {
      border-radius: 50%;
    }
  }

  // Checked state
  input:checked + .c-label--radio::after {
    content: '';
    position: absolute;
    animation: popLabel var(--transition-duration);
  }

  @keyframes popLabel {
    0% {
      opacity: 0;
    }

    25% {
      transform: scale(0.5);
      opacity: 0.25;
    }

    75% {
      transform: scale(1.25);
      opacity: 0.75;
    }

    100% {
      opacity: 1;
    }
  }

  // Checkbox
  .c-label--checkbox::after {
    top: 0.6rem;
    left: 0.125rem;
    width: 0.375rem;
    height: 0.65rem;
    border-width: 2px 2px 0 0;
    border-style: solid;
    border-color: theme(label--checkbox__after-bdc);
    transform: scaleX(-1) rotate(135deg);
    transform-origin: left top;
  }

  // Checked state
  input:checked + .c-label--checkbox::before {
    background-color: theme(brand);
  }

  input:checked + .c-label--checkbox::after {
    content: '';
    position: absolute;
  }

  input:checked + .c-label--checkbox::after {
    animation: drawLabel var(--animation-duration);
  }

  @keyframes drawLabel {
    0% {
      width: 0;
      height: 0;
      opacity: 1;
    }

    20% {
      width: 0.375rem;
      height: 0;
      opacity: 1;
    }

    40% {
      width: 0.375rem;
      height: 0.65rem;
      opacity: 1;
    }

    100% {
      width: 0.375rem;
      height: 0.65rem;
      opacity: 1;
    }
  }

  // Label with link
  .c-label__link {
    color: inherit;

    #{$interactions} {
      color: inherit;
    }
  }

  // Image
  .c-label--image {
    margin-bottom: 0;
    border: 1px solid transparent;

    input:checked + & {
      border-color: theme(brand);
    }
  }

  // Font
  .c-label--image-font {
    width: 100%;
    padding-left: rem(8); // 8px
    line-height: lh(64, 14); // 64px

    @include mq($from: S) {
      padding-left: rem(16); // 16px
    }
  }

  // Font preview
  .c-label__font {
    float: right;
  }

  // Location
  .c-label--image-location {
    display: inline-block;
    width: calc(50% - #{rem(4)}); // 50% - 4px
    margin-bottom: rem(56); // 2 line-height + 8px
    text-align: center;
    border-radius: 4px;

    @include mq($from: S) {
      margin-bottom: rem(32); // 1 line-height + 8px
    }

    span {
      /* stylelint-disable-line */
      position: absolute;
      top: calc(100% + 4px);
      right: 0;
      left: 0;
    }
  }

  // Location preview
  .c-label__location {
    display: inline-block;
  }

  // Font
  .c-label--image-rectangle {
    width: 100%;
    padding-left: rem(8); // 8px
    line-height: lh(64, 14); // 64px

    @include mq($from: S) {
      padding-left: rem(16); // 16px
    }
  }

  // Font preview
  .c-label__rectangle {
    float: right;
  }

  // Location
  .c-label--image-square {
    display: inline-block;
    width: calc(50% - #{rem(4)} - 4px); // 50% - 4rem - 4px
    margin-right: 2px;
    margin-left: 2px;
    margin-bottom: rem(56); // 2 line-height + 8px
    text-align: center;

    @include mq($from: S) {
      margin-bottom: rem(32); // 1 line-height + 8px
    }

    span {
      /* stylelint-disable-line */
      position: absolute;
      top: calc(100% + 4px);
      right: 0;
      left: 0;
    }
  }

  // Location preview
  .c-label__square {
    display: block;
    margin: 0 auto;
  }
}
