.c-netreviews--desktop {
  position: fixed;
  bottom: 0.5rem;
  right: 0.5rem;
  z-index: 666;
  display: block !important;

  @media (hover: none) and (min-width: 700px),
    (hover: hover) and (min-width: 700px) and (max-width: 49.99em),
    (-ms-high-contrast: none) and (min-width: 700px) and (max-width: 49.99em) {
    bottom: 4.5rem !important;
  }
}

.c-netreviews--mobile {
  z-index: 1 !important;
  bottom: 0.5rem;

  a {
    margin: 0 auto;
  }

  @include mq($until: S) {
    position: relative;
    margin-bottom: 1rem;
  }

  @include mq($from: S) {
    display: none;
  }

  @media (hover: none),
    (hover: hover) and (max-width: 49.99em),
    (-ms-high-contrast: none) and (max-width: 49.99em) {
    display: block;
  }
}
