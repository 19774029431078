@if $toc {
  /* - Confirmation_____________Order confirmation */
} @else {
  /* Order confirmation [component]
    ========================================================================== */

  // Root element
  .c-confirmation {
    padding-top: rem(24); // 24px

    @include mq($from: S) {
      padding-top: rem(72); // 72px
    }
  }

  // Content
  .c-confirmation__content {
    background-image: url('../../../../svg/icon/confirmation.svg');
    background-repeat: no-repeat;

    @include mq($until: S) {
      padding-top: rem(116); // 116px
      background-position: 50% 0;
      background-size: #{rem(100)} #{rem(100)};
    }

    @include mq($from: S) {
      padding-top: rem(170); // 170px
      background-position: 0 0;
      background-size: #{rem(150)} #{rem(150)};
    }
  }

  // Wrapper button
  .c-confirmation__wrapper {
    .c-btn {
      margin-bottom: rem(16); // 16px
    }
  }

  // Title
  .c-confirmation__title {
    @extend %c-h4;
    margin-bottom: rem(8); // 8px

    @include mq($from: S) {
      margin-bottom: rem(16); // 16px
    }
  }

  // Buttons wrapper
  .c-confirmation__wrapper {
    @include mq($until: S) {
      display: flex;
      flex-direction: column-reverse;
      align-items: center;
    }
  }

  [class].c-confirmation__wrapper .c-btn--primary {
    @include mq($until: S) {
      margin-bottom: rem(16); // 16px
      margin-left: 0;
    }
  }
}
