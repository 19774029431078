@if $toc {
  /* - Order summary____________Order summary */
} @else {
  /* Order summary [component]
    ========================================================================== */

  // Root element
  .c-order-summary {
    font-size: rem(14); // 14px
    line-height: lh(21, 14); // 21px
    text-align: left;

    @include mq($until: S) {
      width: calc(100% + #{rem(32)}); // 100% + 32px
      margin-bottom: rem(32); // 32px
      margin-left: rem(-16); // -16px
    }

    @include mq($from: S) {
      margin-bottom: rem(56); // 56px
      text-align: center;
    }
  }

  // Caption
  .c-order-summary__caption {
    @include mq($until: S) {
      padding: 0 rem(16); // 0 16px
    }
  }

  // Header
  .c-order-summary__head {
    @include mq($until: S) {
      @include visually-hidden($extend: false, $important: false);
    }

    th {
      /* stylelint-disable-line */
      padding: rem(12) rem(12); // 12px 16px

      font-weight: normal;
      text-transform: uppercase;
      background-color: theme(order-summary-th__bgc);
      border: 0;
    }
  }

  // Body cells
  .c-order-summary__body td {
    font-weight: 600;

    @include mq($until: S) {
      display: block;
      padding: 0 rem(32) rem(8); // 0 32px 8px 32px
      border-bottom: 0;

      &::before {
        display: block;
        margin: 0 rem(-16) rem(8); // 0 -16px 8px -16px
        padding: rem(6) rem(16); // 6px 16px
        font-weight: normal;
        text-align: left;
        text-transform: uppercase;
        background-color: theme(order-summary-th__bgc);
      }
    }

    @include mq($from: S) {
      $width: 10%;
      width: $width;
      padding: rem(16) 0; // 16px 0
    }
  }

  // Purchase order number
  [class].c-order-summary__number {
    @include mq($until: S) {
      padding-top: rem(32); // 32px
    }
  }

  // Adress & Action cells
  [class].c-order-summary__address,
  [class].c-order-summary__action {
    @include mq($from: S) {
      $length: $grid-col * 3 + $grid-gutter * 2;
      $width: calc($length / 1440) * 100%;
      width: $width;
    }
  }

  // Chosen delivery method
  .c-order-summary__delivery-mode {
    display: block;
    font-weight: normal;
  }

  // Action cell
  [class].c-order-summary__action {
    padding-top: rem(8); // 8px
    padding-bottom: rem(24); // 24px

    @include mq($until: S) {
      text-align: center;
      border-bottom: 1px solid theme(default-bdc);
    }

    @include mq($from: S) {
      $length: calc($grid-col * 4 + $grid-gutter * 3 / 1440) * 100%;
      width: $length;
    }
  }

  // Action buttons
  .c-order-summary__action .c-btn {
    margin: rem(8) rem(12); // 8px 12px
  }

  // Pseudo elements
  @include mq($until: S) {
    .c-order-summary__number::before {
      content: 'N° de commande';
    }

    .c-order-summary__date::before {
      content: 'Date de livraison';
    }

    .c-order-summary__address::before {
      content: 'Adresse de livraison';
    }

    .c-order-summary__price::before {
      content: 'Prix';
    }

    .c-order-summary__action::before {
      content: 'Commande';
    }
  }

  // Specific case
  // Placed orders
  .c-order-summary--placed {
    /* stylelint-disable-line */
    margin-bottom: rem(64); // 64px

    @include mq($from: S) {
      margin-bottom: rem(112); // 112px
    }
  }

  .c-order-summary--placed tr:last-child .c-order-summary__action {
    @include mq($until: S) {
      border-bottom: 0;
    }
  }
}
