@if $toc {
  /* - Product table____________Product table */
} @else {
  /* Product table [component]
    ========================================================================== */

  // Wrapper
  .c-product-table {

    // <table>
    [role="grid"] {
      width: 100%;
      text-align: center;

      @include mq($until: S) {
        font-size: rem(10); // 12px
        line-height: lh(21, 10); // 21px
      }

      @include mq($from: S) {
        font-size: rem(12); // 12px
        line-height: lh(24, 12); // 24px
      }
    }

    // <tr>
    [role="row"] {
      display: table;
      width: 100%;
      margin-bottom: 0;
      border-bottom: 1px solid theme(default-bdc);
    }

    // Label
    .c-label {
      margin-bottom: 0;
      padding-left: 0;
      text-align: center;
      font-size: inherit; // Reset .c-label default font-size
      line-height: inherit;
    }

    // <th> & <td>
    [role="columnheader"],
    [role="gridcell"] {
      display: table-cell;
      padding-top: rem(16); // 16px
      padding-bottom: rem(16); // 16px
      vertical-align: top;
    }

    // <th>
    [role="columnheader"] {
      font-weight: 600;
    }
  }


  // Radio button
  .c-product-table .c-label {
    &::before {
      top: rem(16); // 16px
    }

    &::after {
      top: rem(20); // 20px
    }
  }


  // Diameter cell
  [role="gridcell"].c-product-table__diameter {
    padding-left: em(21, 14); // 21px
  }


  // Delivery cell
  [class].c-product-table__delivery {
    font-weight: 600;
  }


  // Highlighted cell
  .c-product-table__mark {
    &, mark {
      color: theme(product-table__mark-txt);
      background-color: theme(product-table__mark-bgc);
    }
  }


  // Soft reset
  .c-product-table {
    [role="gridcell"],
    span {
      color: inherit;

      &::before {
        content: normal;
      }
    }
  }


  // Cropped table
  .c-product-table.is-cropped {
    position: relative; // Necessary for gradient positioning
    max-height: rem(180); // 180px = 5 × 36 (1 <tr>)
    overflow: hidden;

    &::after {
      content: "";
      position: absolute;
      right: 0;
      bottom: 0;
      left: 0;
      height: rem(65); // 72px (1 <tr> + 1px bordure + 8px : margin-bottom)
      background-image: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, .5) 15%, #fff 90%);
    }
  }


  // Table variations
  // The table has 4 columns
  .c-product-table--4-cols {
    @include mq($until: M) {
      [role="columnheader"],
      [role="gridcell"] {
        width: 22%;
      }

      .c-product-table__delivery {
        width: 34%;
      }
    }

    @include mq($from: M) {
      [role="columnheader"],
      [role="gridcell"] {
        width: 15%;
      }

      .c-product-table__delivery {
        width: 55%;
      }
    }
  }

  // The table has 5 columns
  .c-product-table--5-cols {
    @include mq($until: M) {
      [role="columnheader"],
      [role="gridcell"] {
        width: 18%;
      }

      .c-product-table__delivery {
        width: 28%;
      }
    }

    @include mq($from: M) {
      [role="columnheader"],
      [role="gridcell"] {
        width: 13%;
      }

      .c-product-table__delivery {
        width: 48%;
      }
    }
  }
}
