@if $toc {
  /* - Basket summary___________Basket summary */
} @else {
  /* Basket summary [component]
    ========================================================================== */

  // Content
  .c-basket-summary {
    @include mq($from: S) {
      position: sticky;
      top: rem(24); // 24px
    }
  }

  .c-basket-summary__amount {
    color: theme(default-txt);
    font-size: rem(20); // 20px
    line-height: 1;
  }


  .c-basket-summary .c-disclosure--cart {
    width: auto;
    min-width: rem(132); // 132px
  }


  // Sticky basket summary
  @include mq($until: S) {
    .c-basket-summary--sticky {
      position: fixed;
      top: 0;
      right: 0;
      left: 0;
      z-index: 666;

      .c-cart-total {
        padding-top: 0;
      }

      .c-cart-total__title {
        display: none;
      }
    }
  }

  @include mq($from: S) {
    .c-basket-summary--sticky {
      .c-cart-total {
        display: block;
      }

      .c-basket-summary__header {
        display: none;
      }
    }
  }
}
