@if $toc {
  /* - Payment mode_____________Payment mode */
} @else {
  /* Payment mode [component]
    ========================================================================== */

  // Title
  .c-payment-mode__title {
    display: block;
  }

  // List
  [class].c-payment-mode__list {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    margin-top: rem(16); // 16px
    //width: 100%;

    // Usefull to force line-break
    &::before {
      content: '';
      order: 1;
      width: 100%;
      height: 1px;
    }
  }

  // List items
  .c-payment-mode__item {
    //margin-right: rem(16); // 16px
    flex-basis: calc(33% - #{rem(16)} / 3); // 25% = 100% ÷ 4

    // Force line-break after the third item
    // &:nth-child(n + 5) {
    //   order: 1;
    // }

    // All items except those in the last line
    &:not(:nth-child(-n + 3)) {
      margin-top: rem(12); // 12px
    }

    // Last items of the line
    &:nth-child(3n) {
      margin-right: 0;
    }
  }

  // Logo
  .c-payment-mode__logo {
    margin-right: auto;
    margin-left: auto;
  }

  // Specific case
  // Cart
  .c-payment-mode--cart {
    margin-top: rem(16); // 16px
    padding-right: rem(16);
    padding-left: rem(16);
    text-align: center;
    color: theme(payment-mode-txt);
    font-size: rem(14); // 14px
    line-height: lh(21, 14); // 21px

    @include mq($from: S) {
      margin-top: rem(24); // 24px
    }
  }

  .c-payment-mode--cart .c-payment-mode__title {
    font-size: rem(12); // 12px
  }

  // Footer
  .c-payment-mode--footer {
    @include mq($from: M) {
      max-width: rem(342); // 342px
    }
  }

  .c-payment-mode--footer {
    .c-payment-mode__title {
      margin-bottom: rem(16); // 16px
      font-weight: normal;
    }

    .c-payment-mode__title,
    small {
      font-size: rem(14); // 14px
    }
  }

  // All items except those in the last line
  // .c-payment-mode--footer
  //   .c-payment-mode__item:nth-child(-n + 4)
  //   .c-payment-mode__logo {
  //   max-height: rem(24); // 24px
  // }
}
