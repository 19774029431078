@if $toc {
  /* - Breadcrumb_______________Breadcrumb */
} @else {
  /* Breadcrumb [component]
  ========================================================================== */

  // List
  [class].c-breadcrumb {
    margin-top: rem(16); // 16px
    padding: rem(8) rem(16); // 8px 16px
    background-color: theme(breadcrumb-bgc);

    @include mq($until: S) {
      display: none;
    }
  }

  // Items
  .c-breadcrumb__item {
    margin-right: rem(8); // 8px

    &::after {
      content: '';
      display: inline-block;
      width: 1px;
      height: rem(14); // 12px
      margin-left: rem(8); // 8px
      vertical-align: -2px;
      background-color: currentColor;
      transform: skew(-20deg);
    }

    &:last-child::after {
      content: normal;
    }
  }

  // Links
  .c-breadcrumb__link {
    display: inline;
    color: theme(breadcrumb__link-txt);
    font-weight: 400;
    font-size: rem(12); // 12px
    line-height: lh(18, 12); // 18px

    #{$interactions} {
      color: inherit;
    }
  }

  // Specific case
  // Breadcrumb on CMS pages
  .has-transparent-breadcrumb {
    .c-breadcrumb {
      background-color: transparent;
      padding-left: 0;
    }
  }
}
