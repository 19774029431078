@if $toc {
  /* - Pagination_______________Pagination */
} @else {
  /* Pagination [component]
    ========================================================================== */

  // List
  .c-pagination {
    @include mq($until: M) {
      margin-right: auto;
      margin-left: auto;
      text-align: center;
    }
  }


  // Items
  .c-pagination__item {
    vertical-align: middle;
  }


  // Ellipsis
  .c-pagination__item--ellipsis {
    min-width: rem(24); // 24px
    line-height: lh(48, 16); // 48px
    text-align: center;
  }


  // Link
  .c-pagination__link {
    @include unstyled-button($extend: true, $important: false); // Usefull to slider navigation
    min-width: rem(24); // 24px
    color: theme(default-txt);
    font-weight: 400;
    font-size: rem(14); // 14px
    line-height: lh(48, 14); // 48px
    text-align: center;

    // Current page
    &[aria-current="page"] {
      position: relative;
      color: theme(pagination__link-txt_current);

      &::after {
        content: "";
        position: absolute;
        right: 2px;
        bottom: 8px;
        left: 2px;
        height: 1px;
        background-color: theme(brand);
      }
    }

    #{$interactions} {
      color: theme(brand);
      text-decoration: none;
    }
  }


  // Previous / Next
  .c-pagination__link--previous,
  .c-pagination__link--next {
    width: rem(46); // 46px
    height: rem(46); // 46px
    color: theme(brand);
    border: 1px solid currentColor;
    border-radius: 50%;

    #{$interactions} {
      background-color: theme(brand);
      outline: 0;
      color: theme(pagination__link_hover);
    }
  }

  .c-pagination__link--previous {
    margin-right: rem(16); // 16px
    transform: scale(-1, 1); // Mirror icon
  }

  .c-pagination__link--next {
    margin-left: rem(16); // 32px
  }

  // Inverted pagination
  .c-pagination--inverted .c-pagination__link {
    color: theme(pagination--inverted__link-txt);
    border-color: currentColor;
    background-color: theme(pagination--inverted__link-bgc);

    #{$interactions} {
      color: theme(pagination--inverted__link-txt_hover);
      background-color: theme(pagination--inverted__link-bgc_hover);
    }
  }


  // Icons
  .c-pagination__icon {
    display: inline-block;
    vertical-align: sub;
    color: inherit;
  }



  // Specific case
  // Slider
  .c-slider {
    .c-pagination__item {
      position: absolute;
      display: none;

      @include mq($from: S) {
        display: block;
      }
    }

    .c-pagination__link {
      margin-right: 0;
    }
  }


  // Slider large
  .c-slider--large {
    @include mq($from: S) {
      .c-pagination__item {
        top: 30%;
      }

      .c-pagination__item--previous {
        left: rem(-80); // -80px
      }

      .c-pagination__item--next {
        right: rem(-80); // -80px
      }
    }

    @include mq($from: M) {
      .c-pagination__item {
        top: 25%;
      }

      .c-pagination__item--previous {
        left: rem(-96); // -96px
      }

      .c-pagination__item--next {
        right: rem(-96); // -96px
      }
    }

    @include mq($from: L) {
      .c-pagination__item {
        top: 30%;
      }

      .c-pagination__item--previous {
        left: rem(-120); // -120px
      }

      .c-pagination__item--next {
        right: rem(-120); // -120px
      }
    }
  }


  // Opposite slider
  .c-slider--opposite {
    @include mq($from: S) {
      .c-pagination__item {
        bottom: 0;
      }

      .c-pagination__item--previous {
        right: rem(56); // 56px
      }

      .c-pagination__item--next {
        right: 0;
      }
    }
  }


  // Product slider
  .c-slider--product {
    @include mq($from: S) {
      .c-pagination__item {
        top: auto;
        margin-top: rem(-62); // 62px = 46px + 16px : height of c-pagination__item + 16px
      }

      .c-pagination__item--previous {
        right: rem(72); // 72px
      }

      .c-pagination__item--next {
        right: rem(16); // 16px
      }
    }
  }
}
