@if $toc {
  /* - CSS Var__________________Define colors, animation/transition times and grid characteristics */
} @else {
  /* Colors [settings]
  ========================================================================== */

  :root {
    // Default value of duration for transitions and animations
    // @see [Implementing a reduced-motion mode](https://hugogiraudel.com/2018/03/19/implementing-a-reduced-motion-mode/)
    --animation-duration: 1s;
    --transition-duration: 250ms;
  }
}

// Grid characteristics (in px)
$grid-col: 98;
$grid-gutter: 24;
$grid-gutter--us: 16;

// Definition (color)
// @note Colors are named thanks to the [Chir.ag’s Name that Color utility](http://chir.ag/projects/name-that-color/).
$alabaster: #fbfbfb;
$chablis: #fff5f5;
$coral-red: #ff652d;
$dusty-gray: #979797;
$grenadier: #e92200;
$mercury: #e2e2e2;
$mine-shaft: #242424;
$fair-pink: #ffefec;
$marigold: #ba8d26;
$portage: #7f9beb;
$rose-bud: #f8a9a9;
$scarlet: #ff2e0a;
$san-marino: #4c69b4;
$tuatara: #3c3c3b;
$white: #fff;
$wild-sand: #f6f6f6;
$zircon: #f8faff;

$alto: #d7d7d7;
$boston-blue: #3a8acb;
$black-squeeze: #f2f8fb;
$cod-gray: #0e0e0e;
$gallery: #eee;
$gray: #939393;
$hot-toddy: #ac7a07;

$spring-wood: #faf9f5;
$sweet-pink: #fe9e9e;

// @see [Ordre des pseudo-classes des liens](http://romy.tetue.net/love-fuck-hate)
$interactions: '&:focus, &:hover, &:active';

// Abbreviations
// - bgc => background-color
// - bgi => background-image
// - bdc => border-color
// - txt => text (color)
// - plh => placeholder (color)

$theme-colors: (
  // Common values
  brand: $scarlet,
  default-bdc: $mercury,
  default-bgc: $white,
  default-outline: $dusty-gray,
  default-txt: $tuatara,
  // Assignation [element]
  del-txt: $dusty-gray,
  form-plh: $dusty-gray,
  form-error-bdc: $coral-red,
  heading-txt: $tuatara,
  input-focus-bdc: $mercury,
  label__span-txt: $dusty-gray,
  mark-bgc: $black-squeeze,
  selection-bgc: $grenadier,
  selection-txt: $chablis,
  // Assignation [component]
  added-to-cart__gift-bgc: $spring-wood,
  background-block-bgc: $spring-wood,
  breadcrumb-bgc: $fair-pink,
  breadcrumb__link-txt: $tuatara,
  btn--primary-bdc: $scarlet,
  btn--primary-bgc: $scarlet,
  btn--primary-txt: $white,
  btn--primary-bdc_hover: $grenadier,
  btn--primary-bgc_hover: $grenadier,
  btn--primary-txt_hover: $white,
  btn--secondary-bdc: $scarlet,
  btn--secondary-bgc: $white,
  btn--secondary-txt: $scarlet,
  btn--secondary-bdc_hover: $scarlet,
  btn--secondary-bgc_hover: $scarlet,
  btn--secondary-txt_hover: $white,
  btn--tertiary-bdc: $tuatara,
  btn--tertiary-bgc: $tuatara,
  btn--tertiary-txt: $white,
  btn--tertiary-bdc_hover: $mine-shaft,
  btn--tertiary-bgc_hover: $mine-shaft,
  btn--tertiary-txt_hover: $white,
  btn--light-bdc: $tuatara,
  btn--light-bgc: transparent,
  btn--light-txt: $tuatara,
  btn--light-bdc_hover: $tuatara,
  btn--light-bgc_hover: $tuatara,
  btn--light-txt_hover: $white,
  btn--ghost-txt: $white,
  btn--ghost-bdc: $white,
  btn--ghost-bgc: transparent,
  btn--ghost-bdc_hover: $scarlet,
  btn--ghost-bgc_hover: $scarlet,
  btn--ghost-txt_hover: $white,
  btn--fill-bdc: transparent,
  btn--fill-bgc: transparent,
  btn--fill-txt: $scarlet,
  btn--fill-txt_hover: $grenadier,
  btn--print-bdc: $fair-pink,
  btn--print-bgc: $fair-pink,
  btn--print-txt: $scarlet,
  btn--print-txt_hover: $grenadier,
  btn--filter-bdc: $tuatara,
  btn--filter-bgc: transparent,
  btn--filter-txt: $tuatara,
  btn--filter-bdc_hover: $tuatara,
  btn--filter-bgc_hover: $tuatara,
  btn--filter-txt_hover: $white,
  btn--sticky-engraving-bgc: $white,
  cart-summary-bdc: $alabaster,
  cart-summary--order-bdc: $wild-sand,
  contextual-information-txt: $dusty-gray,
  cover-bgc: $fair-pink,
  cover--image-txt: $white,
  cover--image__after-bgc: $tuatara,
  datepickr__months-bgc: $grenadier,
  datepickr__months-txt: $white,
  datepickr__selected-bgc: $mine-shaft,
  datepickr__selected-txt: $white,
  datepickr__today-bgc: $mercury,
  faq-nav-bgc: $spring-wood,
  faq-nav__btn-txt: $gray,
  flash-sale-bgc: $spring-wood,
  flash-sale-txt: $marigold,
  footer__legal-bgc: $gallery,
  form--delivery__legend-txt: $marigold,
  form--delivery__fieldset-bdc: $alabaster,
  h4--dark-bgc: $black-squeeze,
  header-bgc: $wild-sand,
  identity-bgc: $zircon,
  label--checkbox__after-bdc: $white,
  link-txt: $scarlet,
  link-txt_hover: $grenadier,
  loyalty-score-bgc: $wild-sand,
  material--gold-txt: $marigold,
  material--rose-gold-txt: $rose-bud,
  material--white-gold-txt: $dusty-gray,
  material--nacre-txt: $portage,
  material--silver-txt: $dusty-gray,
  media__title-txt: $marigold,
  media__illustration__after-bgc: $marigold,
  message--error-bgc: $chablis,
  message--error-txt: $coral-red,
  message--help-bgc: $zircon,
  message--help-txt: $tuatara,
  message--info-bgc: $fair-pink,
  message--info-txt: $tuatara,
  message-view__me-bgc: $wild-sand,
  message-view__timestamp-txt: $dusty-gray,
  modal-overlay-bgc: $cod-gray,
  newsletter-bgc: $zircon,
  newsletter__social-link-bgc: transparent,
  newsletter__social-link-txt: $scarlet,
  newsletter__social-link-bdc: $scarlet,
  newsletter__social-link-txt_hover: $white,
  newsletter__social-link-bgc_hover: $scarlet,
  notice-bgc: $alabaster,
  order-summary-th__bgc: $wild-sand,
  pagination__link-txt_current: $tuatara,
  pagination__link: $scarlet,
  pagination__link_hover: $white,
  pagination--inverted__link-txt: $mine-shaft,
  pagination--inverted__link-bgc: transparent,
  pagination--inverted__link-txt_hover: $white,
  pagination--inverted__link-bgc_hover: $mine-shaft,
  payment-mode-txt: $dusty-gray,
  photo__after-bgc: $tuatara,
  photo__content-txt: $white,
  product-information__reference: $dusty-gray,
  product-table__mark-bgc: $fair-pink,
  product-table__mark-txt: $scarlet,
  progressbar-txt: $tuatara,
  reassurance-bgc: $fair-pink,
  scrollbar-thumb-bgc: $cod-gray,
  scrollbar-track-bgc: $gallery,
  select--quantity-txt: $tuatara,
  sale-type-txt: $white,
  shortcut-product-txt: $white,
  shortcut-bgc: $wild-sand,
  slider__bullet-bgc: $tuatara,
  slider__thumbnail_active-bdc: $grenadier,
  slider--cover__bullet-bgc: $white,
  sponsorship__guideline-bgc: $wild-sand,
  tag--highlight-bgc: $alabaster,
  tag--highlight-txt: $san-marino,
  tag--accessory-bgc: $fair-pink,
  tag--remove-focus-bdc: $mine-shaft,
  tag--remove-bgc: $white,
  voucher-txt: $san-marino,
  voucher-bgc: $zircon,
  // Assignation [utility]
  background-dark-bgc: $wild-sand,
  background-light-bgc: $alabaster
);
