@if $toc {
  /* - Edito____________________Editorial block */
} @else {
  /* Editorial block [component]
    ========================================================================== */

  // Edito
  .c-edito {
    text-align: center;
    border-bottom: 1px solid transparent; // Prevents the lower border of the links from being trimmed when resizing the window
  }


  // Link
  // Reset text-decoration color
  .c-edito__link {
    color: inherit;
  }


  // Illustration
  .c-edito__img {
    margin: 0 auto rem(16); // 0 auto 16px auto
  }


  // Title
  .c-edito__title {
    margin-bottom: 0;
    
    @include mq($until: S) {
      font-size: rem(20); // 20px
      line-height: lh(30, 20); // 30px
    }
    @include mq($from: S) {
      font-size: rem(24); // 24px
      line-height: lh(33, 24); // 33px
      letter-spacing: 0.8px;
    }
  }


  // Content
  .c-edito__content {
    margin-top: rem(8); // 8px
  }


  // Button
  .c-edito .c-btn {
    margin-top: rem(16); // 16px
  }
}
