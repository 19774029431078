@if $toc {
  /* - Sponsorhip_______________Sponsorship */
} @else {
  /* Sponsorship [component]
    ========================================================================== */

  // Root element
  .c-sponsorship {
    margin-bottom: rem(40); // 40px

    @include mq($from: S) {
      margin-bottom: rem(80); // 80px
    }
  }


  // Guideline
  .c-sponsorship__guideline {
    margin-bottom: rem(24); // 24px
    padding: rem(16); // 16px
    background-color: theme(sponsorship__guideline-bgc);

    @include mq($from: S) {
      margin-bottom: rem(48); // 48px
      padding: rem(24); // 24px
      font-size: rem(14); // 14px
      line-height: lh(21, 14); // 21px
    }
  }


  // Columns
  .c-sponsorship__container:first-child {
    @include mq($until: S) {
      margin-bottom: rem(48); // 48px
    }
  }


  // Sponsorship history (table)
  .c-sponsorship__history {
    text-align: left;
    border-top: 1px solid theme(default-bdc);
  }

  // Caption
  .c-sponsorship__caption {
    @include mq($until: S) {
      margin-bottom: rem(16); // 16px
    }
  }

  // Cells
  .c-sponsorship__date,
  .c-sponsorship__mail {
    display: block;
  }

  .c-sponsorship__history tr:last-child td {
    @include mq($until: S) {
      border-bottom: 0;
    }
  }

  // Sponsorship date
  .c-sponsorship__date {
    margin-bottom: rem(8); // 8px
    padding-top: rem(16); // 16px
    padding-bottom: 0;
    border-bottom: 0;
  }

  // Godchildren email
  .c-sponsorship__mail {
    padding-top: 0;
    padding-bottom: rem(16); // 16px
    font-weight: 600;
  }
}
