@if $toc {
  /* - Footer___________________Site footer */
} @else {
  /* Site footer [component]
    ========================================================================== */

  .c-footer {
    @include mq($until: M) {
      border-top: 1px solid theme(default-bdc);
    }

    @include mq($from: S) {
      font-size: rem(14); // 14px
    }

    @include mq($from: M) {
      padding-top: rem(36); // 34px
    }
  }

  .c-footer .o-wrapper--no-space {
    @include mq($until: M) {
      padding-right: 0;
      padding-left: 0;
    }
  }

  // Grid
  /* Width calculation => ([1] - [2] * [3]) /[4]
  *  [1] : full width
  *  [2] : gutter width
  *  [3] : number of gutters
  *  [4] : number of item
  */

  $three-ninths: calc((100% - 3rem) / 3);   /* stylelint-disable-line */ // 3rem = 24px * 2
  $two-twelfths: calc((100% - 7.5rem) / 6); // 7.5rem = 24px * 5
  $three-twelfths: calc((100% - 4.5rem) / 4); // 4.5rem = 24px * 3

  .c-footer__grid {
    display: grid;
    grid-template-areas:
      "product"
      "about"
      "payment"
      "contact"
      "brand";

    @include mq($from: M, $until: L) {
      grid-gap: 0 rem($grid-gutter); // 24px
      grid-template-columns: $three-ninths 1fr 1fr $three-ninths;
      grid-template-areas:
        "brand product about payment"
        "brand product about contact";
    }

    @include mq($from: L) {
      grid-gap: 0 rem($grid-gutter); // 24px
      grid-template-columns: $three-twelfths $two-twelfths $two-twelfths $two-twelfths $three-twelfths;
      grid-template-areas: "brand product about payment contact";
    }
  }

  // Grid areas
  .c-footer__brand {
    grid-area: brand;
  }

  .c-footer__product {
    grid-area: product;
  }

  .c-footer__about {
    grid-area: about;
  }

  .c-footer__payment {
    grid-area: payment;
  }

  .c-footer__contact {
    grid-area: contact;
  }


  // Brand
  .c-footer__brand {
    margin: 0 rem(16) rem($grid-gutter--us); // 0 16px 24px 16px

    @include mq($from: M) {
      margin-right: 0;
      margin-left: 0;
    }

    @include mq($from: L) {
      margin-bottom: 0;
      margin-left: 0;
    }
  }


  // Logo
  .c-footer__brand > a:focus {
    outline: 1px dotted theme(default-outline);
  }

  .c-footer__logo {
    width: rem(164); // 164px
  }

  .c-footer__brand-wrapper {
    @include mq($from: L) {
      max-width: rem(243); // 243px
    }
  }

  .c-footer__brand-copyright {
    display: block;
    margin-top: rem(16); // 16px
    line-height: 1.5;
  }

  .c-footer__brand-review {
    width: rem(180); // 180px
    margin-top: rem(24); // 24px
  }


  // Product
  // About
  .c-footer__product,
  .c-footer__about {
    @include mq($until: M) {
      border-bottom: 1px solid theme(default-bdc);
    }
  }


  // Header
  .c-footer__header {
    // Header are replaced by buttons
    @include mq($until: M) {
      display: none;
    }

    @include mq($from: M) {
      display: block;
      margin-bottom: rem(16); // 8px
      padding: 0;
      font-weight: 600;
      text-transform: uppercase;
    }
  }

  // List
  [class].c-footer__list {
    margin: 0 rem($grid-gutter--us) rem($grid-gutter--us); // 0 16px 16px 16px

    @include mq($from: S) {
      margin-right: rem($grid-gutter); // 24px
      margin-left: rem($grid-gutter); // 24px
    }

    @include mq($from: M) {
      display: block; // Override the dynamically added hidden attribute
      margin-right: auto;
      margin-bottom: rem(32); // 32px
      margin-left: auto;
    }
  }

  // Items
  .c-footer__item {
    margin-bottom: rem(8); // 8px
  }

  // Links
  .c-footer__link {
    color: theme(default-txt);
    font-weight: 400;
    font-size: rem(12); //12px
    line-height: inherit;
  }

  // JS component
  .c-footer .js-disclosure-trigger {
    @include mq($from: M) {
      // Hide disclosure trigger
      display: none;
    }
  }


  // Payment
  .c-footer__payment {
    margin: rem(16) rem($grid-gutter--us) rem(24); // 16px 16px 24px 16px

    @include mq($from: S) {
      margin-right: rem($grid-gutter); // 24px
      margin-left: rem($grid-gutter); // 24px
    }

    @include mq($from: M) {
      margin-top: 0;
      margin-right: 0;
      margin-left: 0;
    }

    @include mq($from: L) {
      margin-bottom: 0;
    }
  }




  // Contact
  .c-footer__contact {
    margin: 0 rem(16) rem($grid-gutter--us); // 0 16px 24px 16px

    @include mq($from: S) {
      margin-right: rem($grid-gutter); // 24px
      margin-left: rem($grid-gutter); // 24px
    }

    @include mq($from: M) {
      max-width: rem(342); // 342px
      margin-left: 0;
    }

    @include mq($from: L) {
      max-width: 100%;
      margin-right: 0;
      margin-bottom: 0;
    }
  }


  // Legal
  .c-footer__legal {
    padding: rem(8); // 8px
    text-align: center;
    background-color: theme(footer__legal-bgc);

    @include mq($from: S) {
      padding: rem(6); // 6px
      font-size: rem(12); // 12px
      line-height: lh(18, 12); // 18px
    }
  }

  .c-footer__legal-item {
    position: relative;

    &:not(:first-child) {
      padding-left: rem(8); // 7px

      &::before {
        content: "";
        position: absolute;
        top: 50%;
        left: 0;
        width: rem(4); // 4px
        height: 1px;
        background-color: currentColor;
      }
    }
  }
}
