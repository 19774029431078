@if $toc {
  /* - Message view_____________Message viewing */
} @else {
  /* Message viewing [component]
    ========================================================================== */

  // Root element
  .c-message-view {
    margin-bottom: rem(64); // 64px
    font-size: rem(14); // 1px
    line-height: lh(21, 14); // 21px

    @include mq($from: S) {
      margin-bottom: rem(96); // 96px
    }
  }


  // Container
  .c-message-view__container {
    margin: rem(16) 0 rem(32); // 16px 0 32px 0
    padding-top: rem(16); // 16px
    border-top: 1px solid theme(default-bdc);
    border-bottom: 1px solid theme(default-bdc);

    @include mq($from: S) {
      margin-top: rem(24); // 24px
      $padding: $grid-col + $grid-gutter;  /* stylelint-disable-line */
      padding: rem(32) rem($padding) 0; // 32px 122px 0 122px
    }
  }


  // Title
  .c-message-view__title {
    @include font($type: regular, $family: $sans-serif);
    font-size: inherit;

  }


  // Message subject
  .c-message-view__subject {
    display: block;
    margin-top: rem(8); // 8px
    font-weight: 600;
  }


  // My message
  .c-message-view__me {
    margin-left: rem(16); // 16px

    @include mq($from: S) {
      margin-left: rem(40); // 40px
    }
  }

  // Site message
  .c-message-view__mdlm {
    margin-right: rem(16); // 16px

    @include mq($from: S) {
      margin-right: rem(40); // 40px
    }
  }


  // Message content
  .c-message-view__content {
    display: block;
    padding: rem(16); // 16px
  }

  // My message
  .c-message-view__me .c-message-view__content {
    background-color: theme(message-view__me-bgc);
  }

  // Site message
  .c-message-view__mdlm .c-message-view__content {
    border: 1px solid theme(default-bdc);
  }


  // Date and time of the message
  .c-message-view__timestamp {
    display: block;
    margin: rem(8) 0 rem(32); // 8px 0 32px 0
    color: theme(message-view__timestamp-txt);

    .c-message-view__me & {
      text-align: right;
    }
  }


  // Submit button
  .c-message-view__action {
    display: block;
    text-align: right;
  }
}

