@if $toc {
  /* - Table____________________Native <table> element */
} @else {
  /* Native <table> [element]
    ========================================================================== */

  table {
    width: 100%;
    text-align: center;
    vertical-align: top;
    border: 0;
    border-collapse: collapse;

    @include mq($from: S) {
      font-size: rem(14); // 14px
      line-height: lh(21, 14); // 21px
    }
  }

  caption {
    text-align: left;
    text-transform: uppercase;
    font-weight: 700;

    @include mq($until: S) {
      margin-bottom: rem(8); // 8px
    }

    @include mq($from: S) {
      margin-bottom: rem(24); // 24px
    }
  }

  th,
  td {
    padding: rem(6) 0; // 6px
    border-bottom: 1px solid theme(default-bdc);
  }

  thead th {
    font-weight: 600;
    border-top: 1px solid theme(default-bdc);
  }
}
