@if $toc {
  /* - Cart summary_____________Shopping cart summary */
} @else {
  /* Shopping cart summary [component]
    ========================================================================== */

  // Variable
  $width: $grid-col + $grid-gutter;

  // Component
  .c-cart-summary {
    text-align: left;

    // Cells
    td {
      padding-top: rem(16); // 16px
      padding-bottom: rem(16); // 16px
      vertical-align: top;
      background-color: theme(default-bgc);

      @include mq($from: M) {
        padding-top: rem(24); // 24px
        padding-bottom: rem(24); // 24px
        border-bottom: rem(16) solid theme(cart-summary-bdc); // 16px
      }
    }

    // Actual price
    ins {
      color: inherit;
    }

    // Accessory
    .c-tag--accessory {
      margin-top: rem(12); // 12px
      margin-left: 0;
    }

    // Gift option
    .c-added-to-cart__gift {
      margin-left: 0;
    }

    // Quantity
    label {
      @include mq($from: S) {
        font-size: rem(12); // 12px
        line-height: lh(24, 12); // 24px
      }
    }

    // Price
    .c-price {
      font-size: rem(18); // 18px
      line-height: lh(24, 18); // 24px
    }

    // Deletion link
    .c-tag--remove {
      margin-right: 0;
      padding-right: rem(24); // 24px
      padding-left: 0;
      background-position: top 50% right 0;
      border: 0;

      @include mq($from: M) {
        margin-right: rem(16); // 16px
      }

      #{$interactions} {
        text-decoration: underline;
      }
    }
  }

  // Image
  [class].c-cart-summary__thumbnail {
    width: rem(96); // 96px

    @include mq($from: M) {
      width: rem(168); // 168px
      text-align: center;
    }
  }

  // Quantity
  .c-cart-summary__quantity {
    flex-grow: 1;

    @include mq($from: M) {
      width: rem($width); // 122px
      padding-left: rem($grid-gutter); // 24px
    }
  }

  // Price
  [class].c-cart-summary__price {
    flex-grow: 0;
    padding-top: rem(
      28
    ); // 28px = 12 + 16 : td padding-top + .c-elect half height
    text-align: right;

    @include mq($from: M) {
      width: rem($width); // 122px
      padding-left: rem($grid-gutter); // 24px
      padding-top: rem(
        64
      ); // 64px = 24 + 40 : td padding-top + .c-select half height
      text-align: center;
    }
  }

  // Removal
  [class].c-cart-summary__removal {
    width: 100%;
    padding-top: rem(16); // 16px

    @include mq($from: M) {
      width: rem($width); // 122px
      padding-left: rem($grid-gutter); // 24px
      padding-top: rem(
        64
      ); // 64px = 24 + 40 : td padding-top + .c-select half height
    }
  }

  // Product name
  .c-cart-summary__product {
    display: block;
    font-size: rem(14); // 14px
    line-height: lh(21, 14); // 21px
    font-weight: normal;
    color: inherit;
  }

  // Product prices
  .c-cart-summary__current-price {
    display: inline-block;
    margin: rem(8) rem(8) 0 0; // 8px 8px 0 0
    font-size: rem(14); // 14px
    line-height: lh(21, 14); // 21px
    font-weight: normal;

    @include mq($from: M) {
      margin-top: rem(12); // 12px
      margin-right: rem(24); // 24px
      font-size: rem(18); // 18px
      line-height: lh(24, 18); // 24px
    }
  }

  .c-cart-summary__former-price {
    margin: rem(8) rem(8) 0 0; // 8px 8px 0 0
    font-size: rem(14); // 14px
    line-height: lh(21, 14); // 21px
    font-weight: normal;

    @include mq($from: M) {
      margin-top: rem(12); // 12px
      margin-right: rem(24); // 24px
      font-size: rem(16); // 16px
      line-height: lh(24, 16); // 24px
    }
  }

  // Engraving details
  .c-cart-summary__engraving {
    font-size: rem(12); // 12px
    line-height: lh(21, 12); // 21px
    margin-top: rem(8); // 8px
  }

  @include mq($until: M) {
    .c-cart-summary {
      // Rows
      tr {
        position: relative;
        display: flex;
        flex-wrap: wrap;
        border-right: rem(16) solid theme(default-bgc);
        border-left: rem(16) solid theme(default-bgc);

        // All <tr> except those in the first line
        &:not(:first-child)::before {
          content: '';
          position: absolute;
          top: 0;
          right: rem(-16); // -16px
          left: rem(-16); // -16px
          height: rem(8); // 8px
          border-bottom: rem(8) solid theme(cart-summary-bdc); // 8px
        }

        &:not(:first-child) {
          .c-cart-summary__thumbnail,
          .c-cart-summary__details {
            padding-top: rem(24); // 24px = 16 + 8 (height of pseudo-element)
          }
        }
      }

      // Accessory
      .c-tag--accessory {
        padding-left: rem(16); // 16px
        background-image: none;
      }

      // Gift option
      .c-added-to-cart__gift {
        margin-top: rem(8); // 8px
      }

      // Select
      .c-select--quantity {
        display: inline-block;
        margin-left: rem(12); // 12px
      }
    }

    // Quantity
    // Price
    // Removal
    [class].c-cart-summary__quantity,
    [class].c-cart-summary__price,
    [class].c-cart-summary__removal {
      border-bottom: 0;
    }

    // Preview
    .c-cart-summary__preview {
      max-width: rem(80); // 80px
    }

    // Details
    .c-cart-summary__details {
      width: calc(100% - #{rem(96)}); // 100% - .c-cart-summary__thumbnail width
    }

    // Removal
    .c-cart-summary__removal {
      width: 100%;
      text-align: right;
    }
  }

  // Dedicated styles for jewellery bag line item
  .c-cart-summary__jewellery-bag {
    p {
      margin-right: 1rem;
    }

    .c-cart-summary__info {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
    }

    mark.c-tag.c-tag--accessory {
      padding: 0;
      margin: 0 auto;
      background-position: 0;
      // Icon is 32px x 32px
      min-height: 32px;
      min-width: 32px;
    }

    .c-cart-summary__preview {
      height: 80px;
      margin: 0 auto;
    }

    @include mq($from: M) {
      .c-cart-summary__preview {
        height: 108px;
      }
    }

    @include mq($from: S) {
      mark.c-tag.c-tag--accessory {
        margin: 0 rem(8);
      }
    }
  }

  // Specific case
  // Order summary
  .c-cart-summary--order {
    // Borders
    td {
      border-bottom-color: theme(cart-summary--order-bdc);
    }

    tr:not(:first-child)::before {
      @include mq($until: M) {
        border-bottom-color: theme(cart-summary--order-bdc);
      }
    }

    // Number of items ordered
    .c-cart-summary__price {
      /* stylelint-disable-line */
      text-align: left;
    }

    // Number of items ordered
    .c-cart-summary__item-number {
      display: inline-block;
      color: theme(brand);
      font-size: rem(16); // 16px
      line-height: lh(24, 16); // 24px
      text-align: center;

      &::before {
        content: 'Quantité';
        display: inline-block;
        margin-right: rem(16); // 16px
        color: theme(default-txt);
        font-size: rem(12); // 12px
        line-height: lh(24, 12); // 24px
        text-align: left;

        @include mq($from: M) {
          display: block;
          margin-right: 0;
          margin-bottom: rem(16); // 16px
        }
      }
    }
  }

  @include mq($until: M) {
    .c-cart-summary--order {
      td {
        border-bottom: 0;
      }

      // Quantity
      // Price
      .c-cart-summary__quantity,
      .c-cart-summary__price {
        width: 100%;
        padding-top: 0;
        padding-left: rem(96); // .c-cart-summary__thumbnail width
      }
    }
  }
}
