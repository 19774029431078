@if $toc {
  /* - Tab______________________Tab list & tab panel */
} @else {
  /* Tab list & tab panel [component]
    ========================================================================== */

  // Tablist
  [class].c-tablist {
    margin-top: rem(24); // 24px
    margin-bottom: rem(24); // 24px
    font-size: rem(14); // 14px
    line-height: lh(24, 14); // 24px
    text-transform: uppercase;
  }

  // Client space tablist
  [class].c-tablist--client-space {
    white-space: nowrap; // Force the overflow

    @include mq($from: S) {
      margin-bottom: 0;
      padding-bottom: rem(24); // 24px
      padding-left: rem(32); // 32px
      border-bottom: 1px solid theme(default-bdc);
    }
  }

  // Sticky on mobile
  [class].c-tablist--sticky {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    z-index: 5;
    margin: 0;
    padding: rem(16) rem($grid-gutter--us); // 16px 16px
    background-color: theme(default-bgc);

    @include mq($from: S) {
      display: none;
    }
  }

  // Items
  .c-tablist__item:not(:last-child) {
    margin-right: rem(32); // 32px

    @include mq($from: S) {
      margin-right: rem(40); // 40px
    }
  }

  // Links
  .c-tablist__link {
    position: relative; // Usefull to ::after
    color: theme(default-txt);
    cursor: pointer;

    #{$interactions} {
      color: theme(brand);
      text-decoration: none;
    }

    &:focus {
      outline: 1px dotted theme(default-outline);
    }

    // Current panel
    // Current page
    // Current location
    &[aria-selected='true'],
    &[aria-current='page'],
    &[aria-current='location'] {
      color: theme(brand);

      &::after {
        content: '';
        position: absolute;
        right: rem(-2); // -2px
        bottom: rem(-8); // -8px
        left: rem(-2); // -2px
        height: 1px;
        background-color: currentColor;
      }
    }
  }

  // Tabpanel
  .c-tabs [aria-hidden='true'].c-tabpanel {
    display: none;
  }

  // Specific case
  // Home tabs
  .c-tabs--home {
    position: relative; // Usefull to .c-tablist & border

    @include mq($until: S) {
      margin-bottom: rem(16); // 16px
    }

    @include mq($from: S) {
      margin-bottom: rem(80); // 80px
    }

    // Border
    &::before {
      content: '';
      position: absolute;
      border: 1px solid theme(default-bdc);
      z-index: -1;

      @include mq($until: S) {
        top: 0;
        right: rem(16); // 16px
        bottom: 0;
        left: rem(16); // 16px
      }

      @include mq($from: S) {
        right: 0;
        bottom: rem(50); // 50px
        left: 0;
      }

      @include mq($from: S, $until: M) {
        top: rem(56); // 56px
      }

      @include mq($from: M) {
        top: rem(50); // 50px
      }
    }
  }

  // Tablist
  .c-tabs--home .c-tablist {
    @include mq($until: S) {
      margin: 0 rem(32) rem(24); // 0 32px 24px 32px
      padding-top: rem(16); // 16px
    }

    @include mq($from: S) {
      position: absolute;
      top: 0;
    }

    @include mq($from: S, $until: M) {
      left: rem(32); // 32px
      margin-top: rem(8); // 8px
    }

    @include mq($from: M) {
      margin-top: rem(74); // = 50px + 24px (border's vertical margins)
    }

    @include mq($from: M, $until: L) {
      left: rem(48); // 48px
    }

    @include mq($from: L) {
      $left: calc(($grid-col + $grid-gutter) / 2);
      left: #{rem($left)};
    }
  }

  // Illustration
  .c-tabs--home .c-tabpanel__illustration {
    @include mq($from: S) {
      align-self: center;
      justify-self: center;
    }

    @include mq($from: S, $until: M) {
      padding-right: rem(32); // 32px
    }

    @include mq($from: M, $until: L) {
      $margin: calc(($grid-col + $grid-gutter) / 2);
      margin-right: #{rem($margin)};
    }

    @include mq($from: L) {
      $margin: $grid-col + $grid-gutter;
      margin-right: #{rem($margin)};
    }
  }

  .c-tabs--home .c-tabpanel__illustration img {
    @include mq($until: S) {
      width: 100%;
    }
  }
  /*.c-tabs--home  .c-tabpanel__illustration {
    margin-bottom: rem(16); // 16px

    @include mq($from: S) {
      margin-bottom: rem(48); // 48px
    }
  }*/

  // Content
  .c-tabs--home .c-tabpanel__content {
    @include mq($until: S) {
      padding: 0 rem(32) rem(24); // 0 32px 24px 32px
    }

    @include mq($from: S) {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      margin: rem(48) 0 0; // 48px = 32px + 16px
      // 32px: .c-tablist's height
      // 16px: add little space
      padding: 0 rem(32); // 32px
    }

    @include mq($from: S, $until: M) {
      margin-top: rem(80); // 80px = 56px + 24px
      // 56px = tablist's height
      // 24px = top space
      padding-right: 0;
    }

    @include mq($from: M) {
      margin-top: rem(122); // tablist's height & vertical margin
    }

    @include mq($from: M, $until: L) {
      padding-left: rem(48); // 48px
    }

    @include mq($from: L) {
      $padding: calc(($grid-col + $grid-gutter) / 2);
      padding: 0 rem($padding); // 61px
    }
  }

  // Title
  .c-tabs--home .c-tabpanel__title {
    @include mq($until: S) {
      margin-bottom: rem(8); // 8px
    }

    @include mq($from: S, $until: M) {
      margin-bottom: rem(16); // 16px
    }

    @include mq($from: M) {
      margin-bottom: rem(24); // 24px
    }
  }

  // Text
  .c-tabs--home .c-tabpanel__text {
    @include mq($until: M) {
      margin-bottom: rem(16); // 16px
    }

    @include mq($from: M) {
      margin-bottom: rem(32); // 32px
    }
  }

  // Button
  .c-tabs--home .c-btn {
    @include mq($from: S) {
      margin-bottom: rem(74); // 74px = 50px + 24px
      // 50px = border position from bottom
      // 24px = bottom space
    }
  }

  // Specific case
  // Relay point

  // Tablist
  .c-tabs--relay-point .c-tablist__item {
    min-width: rem(104); // 104px
    text-align: center;
  }

  // Tabpanel
  @include mq($from: M) {
    .c-tabs--relay-point {
      display: flex;
      flex-direction: row-reverse;
      justify-content: space-between;

      [aria-hidden='true'].c-tabpanel {
        display: block;
      }

      .c-tabpanel__map {
        @include ratio-width(
          $item: 4,
          $total-item: 9,
          $gutter-width: $grid-gutter--us
        );
      }

      .c-tabpanel__list {
        @include ratio-width(
          $item: 5,
          $total-item: 9,
          $gutter-width: $grid-gutter--us
        );
        position: relative;
        overflow-y: scroll;
        scrollbar-width: thin; // "auto" or "thin"
        scrollbar-color: theme(scrollbar-thumb-bgc) theme(scrollbar-track-bgc); //scroll thumb & track

        &::before {
          content: '';
          display: block;
          padding-top: 110%; // Give a ratio of 1.10
        }

        // Scrollbar definition
        &::-webkit-scrollbar {
          width: rem(8); // 8px = width of the entire scrollbar
        }

        &::-webkit-scrollbar-thumb {
          background-color: theme(
            scrollbar-thumb-bgc
          ); // color of the scroll thumb
          border-radius: rem(8); // roundness of the scroll thumb
        }

        &::-webkit-scrollbar-track {
          background-color: theme(
            scrollbar-track-bgc
          ); // color of the tracking area
        }
      }
    }
  }
}
