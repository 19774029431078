@if $toc {
  /* - Select___________________Customized select */
} @else {
  /* Customized select [component]
    ========================================================================== */

  /**
  * @see [Styling a Select Like It’s 2019](https://www.filamentgroup.com/lab/select-css.html)
  */

  .c-select {
    margin: 0;
    padding-right: rem(48); // 48px
    background-image:
      url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB3aWR0aD0iMTNweCIgaGVpZ2h0PSI4cHgiIHZpZXdCb3g9IjAgMCAxMyA4IiB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiPgogICAgPHRpdGxlPmNoZXZyb24tc21hbGwtcmlnaHQ8L3RpdGxlPgogICAgPGcgaWQ9IlN0eWxlZ3VpZGUiIHN0cm9rZT0ibm9uZSIgc3Ryb2tlLXdpZHRoPSIxIiBmaWxsPSJub25lIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiPgogICAgICAgIDxnIGlkPSJGb3JtdWxhaXJlLS0tU3R5bGVndWlkZS0tLU1haXNvbl9kZV9sYV9NZWRhaWxsZSIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoLTY0Ny4wMDAwMDAsIC03NjMuMDAwMDAwKSIgZmlsbD0iIzNDM0MzQiIgZmlsbC1ydWxlPSJub256ZXJvIj4KICAgICAgICAgICAgPGcgaWQ9Ikdyb3VwLTUiIHRyYW5zZm9ybT0idHJhbnNsYXRlKDI5Mi4wMDAwMDAsIDcxOC4wMDAwMDApIj4KICAgICAgICAgICAgICAgIDxnIGlkPSJpY29uL2Fycm93LWNvcHkiIHRyYW5zZm9ybT0idHJhbnNsYXRlKDM2MS41MDAwMDAsIDQ5LjAwMDAwMCkgcm90YXRlKC0yNzAuMDAwMDAwKSB0cmFuc2xhdGUoLTM2MS41MDAwMDAsIC00OS4wMDAwMDApIHRyYW5zbGF0ZSgzNTguMDAwMDAwLCA0My4wMDAwMDApIj4KICAgICAgICAgICAgICAgICAgICA8ZyBpZD0iY2hldnJvbi1zbWFsbC1yaWdodCIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoLTAuMDAwMDAwLCAwLjAwMDAwMCkiPgogICAgICAgICAgICAgICAgICAgICAgICA8cGF0aCBkPSJNNC4zNTg3NzQ3Nyw2LjAwMDE2MjU4IEwwLjI2MjE1MjUyMiwxLjUzOTY1Njk4IEMtMC4wODczODQxNzQxLDEuMTg3NTExOCAtMC4wODczODQxNzQxLDAuNjE3NTU4MzA1IDAuMjYyMTUyNTIyLDAuMjY0MTA4ODg0IEMwLjYxMTY4OTIxOCwtMC4wODgwMzYyOTQ4IDEuMTc2NDI1NzUsLTAuMDg4MDM2Mjk0OCAxLjUyNTk2MjQ0LDAuMjY0MTA4ODg0IEw2LjUyMTIwNzAyLDUuMzYxMDg0MjkgQzYuODcwNzQzNzIsNS43MTQ1MzM3MSA2Ljg3MDc0MzcyLDYuMjg1NzkxNDUgNi41MjEyMDcwMiw2LjYzNzkzNjYzIEwxLjUyNTk2MjQ0LDExLjczNDkxMiBDMS4xNzc3Mjk5OSwxMi4wODk2NjU3IDAuNjExNjg5MjE4LDEyLjA4NzA1NzIgMC4yNjIxNTI1MjIsMTEuNzM0OTEyIEMtMC4wODczODQxNzQxLDExLjM4Mjc2NjkgLTAuMDg3Mzg0MTc0MSwxMC44MTI4MTM0IDAuMjYyMTUyNTIyLDEwLjQ1OTM2MzkgTDQuMzU4Nzc0NzcsNi4wMDAxNjI1OCBaIiBpZD0iUGF0aCI+PC9wYXRoPgogICAgICAgICAgICAgICAgICAgIDwvZz4KICAgICAgICAgICAgICAgIDwvZz4KICAgICAgICAgICAgPC9nPgogICAgICAgIDwvZz4KICAgIDwvZz4KPC9zdmc+"),
      linear-gradient(to bottom, theme(default-bgc) 0%, theme(default-bgc) 100%);
    background-repeat: no-repeat, no-repeat;
    background-position: right rem(16) top 50%, 0 0;
    background-size: 1rem rem(10), 100% auto;
  }

  .c-select::-ms-expand {
    display: none;
  }

  .c-select option {
    font-family: sans-serif; // Apply sans-serif font
  }

  // Quantity
  .c-select--quantity {
    width: rem(90); // 90px
    color: theme(select--quantity-txt);
  }

  // Inline select
  .c-select--inline {
    display: inline-block;
    width: auto;
    margin-left: rem(12); // 12px
  }
}
