@if $toc {
  /* - Page_____________________Page-level <html> and <body> */
} @else {
  /* Default html and body styles [element]
   ========================================================================== */

  html {
    overflow-x: hidden; // Avoids horizontal scrollbar due to the pseudo-elements placed outside of the screen
    scroll-behavior: smooth;
  }

  body {
    @include font($type: regular, $family: $sans-serif);
    overflow-x: inherit;
    color: theme(default-txt);
    font-size: rem(14); // 14px
    line-height: 1.5; // 21px
    letter-spacing: .4px;
    background-color: theme(default-bgc);
    counter-reset: step; // Set a counter named 'step', and its initial value is 0

    // Targets touch type terminals, non-touch terminals that have a width of less than 50em and IE11 under 50em
    @media (hover: none),
      (hover: hover) and (max-width: 49.99em),
      (-ms-high-contrast: none) and (max-width: 49.99em) {
      padding-bottom: rem(60); // 60px (= c-shortcut's height)
    }
  }
}
