@if $toc {
  /* - Voucher__________________Promotional code and loyalty points */
} @else {
  /* Promotional code and loyalty points [component]
    ========================================================================== */

  // BEM root element
  // .c-voucher


  // Content
  .c-voucher__content {
    padding: 0 rem(16) rem(16); // 0 16px 16px 16px
    background-color: theme(default-bgc);

    @include mq($from: S) {
      font-size: rem(14); // 14px
      line-height: lh(21, 14); // 21px
    }
  }


  // Promotion code field
  .c-voucher__input {
    width: calc(100% - #{rem(120)}); // 100% - 120px
  }


  // Submit button
  .c-voucher__btn {
    width: rem(120); // 120px
    margin-left: em(-4, 16); // -4px

    @include mq($from: S) {
      margin-left: em(-4, 14); // -4px
    }
  }


  // Validation message
  .c-voucher__validation {
    padding: rem(8); // 8px
    color: theme(voucher-txt);
    background-color: theme(voucher-bgc);

    @include mq($until: S) {
      font-size: rem(14); // 14px
      line-height: lh(21, 14); // 21px
    }

    @include mq($from: S) {
      font-size: rem(12); // 12px
      line-height: lh(21, 12); // 21px
    }
  }
}
