@if $toc {
  /* - Signin___________________Sign-up, sign-in & forgotten password form */
} @else {
  /* Sign-up, sign-in & forgotten password form [component]
    ========================================================================== */

  [class].c-signin {
    padding: rem(16) rem(16) rem(32); // 16px 16px 32px 16px
    background-color: theme(default-bgc);

    @include mq($from: S) {
      position: relative;
      margin: 0 rem(24) rem(40); // 0 24px 40px 24px
      padding: rem(88) 0; // 88px 0
    }

    @include mq($from: L) {
      margin-right: auto;
      margin-left: auto;
    }
  }


  // Title
  .c-signin__title {
    @extend %c-h3;
    text-align: center;

    @include mq($from: S) {
      margin-bottom: rem(32); // 32px
    }
  }

  .u-max-wd-4 .c-signin__title {
    margin-bottom: rem(8); // 8px

    @include mq($from: S) {
      margin-bottom: rem(24); // 24px
    }
  }


  // Forgotten password wrapper
  .c-signin__link {
    margin-bottom: rem(32); // 32px
    text-align: right;

    @include mq($from: S) {
      margin-bottom: rem(16); // 16px
    }
  }



  // Specific case
  // Account pages
  [class].c-signin--account {
    @include mq($from: S) {
      margin-top: rem(24); // 24px
      //margin-bottom: rem(40); // 40px
      padding-top: rem(48); // 48px
    }
  }

  // Tablist
  [class].c-signin--account .c-tablist {
    margin-top: 0;
    margin-bottom: rem(32); // 32px

    @include mq($from: S) {
      margin-bottom: rem(64); // 64px
    }
  }


  // Order as a guest
  .c-signin__guest {
    text-align: center;

    @include mq($until: S) {
      margin-top: rem(32); // 32px
      padding-top: rem(32); // 32px
      border-top: 1px solid theme(default-bdc);
    }

    @include mq($from: S) {
      align-self: center;
      padding-right: rem(12); // 12px
      padding-left: rem(12); // 12px

      &::before {
        content: "";
        position: absolute;
        top: rem(44); // 44px
        bottom: rem(44); // 44px
        left: 50%;
        border-left: 1px solid theme(default-bdc);
      }
    }
  }


  // Forgotten password
  @include mq($from: S) {
    [class].c-signin--password {
      margin-top: rem(24); // 24px
    }

    [class].c-signin--password-mail {
      padding: rem(128) 0; // 128px 0
    }
  }
}
