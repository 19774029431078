#product-description {
  @include mq($until: S) {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }
}

.c-product-description {
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
  position: relative;
  font-family: $serif;
  padding: rem(16); // 16px
  margin-bottom: rem(16); // 16px
  max-width: calc(rem(32) + 585px * 2);

  @include mq($from: S) {
    gap: rem(24);
  }

  .c-product-description__wrapper {
    position: relative;
    order: 2;

    @include mq($from: S) {
      max-width: 40%;
    }
  }

  .c-product-description__title {
    @extend %c-h1;

    @include mq($until: S) {
      margin-bottom: rem(16);
      margin-top: rem(32);
    }

    @include mq($from: S) {
      margin-top: rem(64);
      margin-left: rem(48);
      margin-bottom: rem(64);
    }

    // Separator
    &::after {
      content: '';
      display: block;
      height: 4px;
      width: rem(40); // 40px
      background-color: theme(brand);

      @include mq($until: S) {
        margin-top: rem(16); // 32px
      }

      @include mq($from: S) {
        margin-top: rem(32); // 32px
      }
    }
  }

  .c-product-description__text {
    background-color: #ffefec;
    line-height: 1.5;

    @include mq($until: S) {
      padding: rem(32);
      font-size: 18px;
    }

    @include mq($from: S) {
      width: rem(585);
      position: absolute;
      height: 750px;
      padding: rem(100);
      font-size: rem(24);
      overflow-y: auto;
    }
  }

  .c-product-description__img1 {
    order: 1;
  }
  .c-product-description__img2 {
    order: 3;
    justify-self: flex-start;

    @include mq($until: S) {
      overflow-x: hidden;

      img {
        height: rem(559);
        min-width: rem(704);
      }
    }
  }
}
