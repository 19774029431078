@if $toc {
  /* - Font_____________________Import fonts and define font vars */
} @else {
  /* Fonts [settings]
    ========================================================================== */

  /**
  * Specifies a custom font with which to display text
  * @see [The font display timeline](https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display)
  * @see [Font-weight: Weight name mapping](https://developer.mozilla.org/en-US/docs/Web/CSS/font-weight#Common_weight_name_mapping)
  */
}

// Font-face imports are inline in <head>

// Assignation
$sans-serif: Merriweather, Arial, sans-serif;
$serif     : Playfair, "Times New Roman", serif;
$defaut-font-family: $sans-serif;  // used in `_tools.font.scss`
