@if $toc {
  /* - Contact__________________Contact insert in header, footer  & FAQ */
} @else {
  /* Contact insert [component]
    ========================================================================== */

  // Root element
  .c-contact {
    padding: rem(16); // 16px
    border: 1px solid theme(default-bdc);

    @include mq($until: S) {
      margin-bottom: rem(16); // 16px
    }
  }


  // Catchphrase
  .c-contact__teaser {
    display: inline-block;
    margin-bottom: rem(4); // 4px
    font-size: rem(14); // 14px
    color: theme(default-txt);

    // Footer variation
    .c-footer & {
      font-weight: 700;
    }
  }


  // Phone number
  .c-contact__phone {
    display: block;
    margin-bottom: rem(8); // 8px
    font-size: rem(24); // 24px
    font-family: $serif;
    color: theme(brand);

    // Footer variation
    .c-footer & {
      font-size: rem(16); // 16px
      font-weight: 700;
      font-family: $sans-serif;
    }
  }


  // Opening hours
  .c-header__contact .c-contact__hour {
    // Targets non-touch terminals that have a width greater than or equal to 50em and IE11 below 50em
    @media (hover: hover) and (min-width: 50em),
      (-ms-high-contrast: none) and (min-width: 50em) {
      display: none;
    }
  }


  // Mail
  .c-contact__mail {
    display: block;
    margin-top: rem(8); // 8px
  }
}
