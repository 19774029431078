@if $toc {
  /* - Cross sell____________________Cross sell */
} @else {
  /* Cross sell [component]
    ========================================================================== */

  /**
  * @note We assume that the default font-size is 16px
  */

  .c-cross-sell {
    border-top: 1px solid theme(default-bdc);
    padding-top: rem(24); // 24px

    @include mq($from: S) {
      padding-top: rem(16); // 16px
    }
  }

  // Title
  .c-cross-sell__title {
    @extend %c-h4;
    text-align: center;

    @include mq($from: S) {
      margin-bottom: rem(24); // 24px
    }
  }

  // Content
  .c-added-product__content {
    vertical-align: middle;
    padding-left: rem(16); // 16px

    @include mq($from: S) {
      padding-left: rem(24); // 24px
    }
  }

  // Content
  .c-added-product__title {
    @include font($type: bold, $family: $serif);
    @extend %c-h4;
    display: block;
    margin-bottom: rem(8); // 8px

    @include mq($from: S) {
      margin-bottom: rem(24); // 24px
    }
  }

  // Thumbnail
  .c-added-product__thumbnail {
    width: rem(96); // 96px
    vertical-align: middle;

    @include mq($from: S) {
      width: rem(160); // 160px
    }
  }

  // Preview
  .c-added-product__preview {
    border: 1px solid theme(default-bdc);
    padding: rem(4) rem(8); // 4px 8px

    @include mq($from: S) {
      padding: rem(16) rem(24); // 16px 24px
    }
  }

  #js-modal-content .c-cross-sell {
    display: none;

    @include mq($from: S) {
      display: block;
      border-top: none;
      margin-top: 1rem;
      margin-bottom: 1rem;
    }
  }
}
