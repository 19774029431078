@if $toc {
  /* - Image____________________Standalone images */
} @else {
  /* Standalone images [component]
    ========================================================================== */

  // Logo (header & footer)
  .c-img-logo {
    width: rem(243); // 243px
  }


  /* Social icons (newsletter)
  .c-img-social {
    width: rem(28); // 28px
    height: rem(28); // 28px
  }*/


  // Empty cart
  .c-img-shopping-cart {
    width: rem(128); // 128px

    @include mq($from: S) {
      width: rem(160); // 160px
    }
  }
}
