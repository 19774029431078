@if $toc {
  /* - Cover____________________Homepage headers */
} @else {
  /* Homepage headers [component]
    ========================================================================== */

  // Container
  .c-cover {
    background-color: theme(cover-bgc);
  }

  .c-cover__bottom {
    margin-bottom: 2rem;

    .c-cover__content {
      padding-top: rem(40);
      padding-bottom: rem(40);
      margin: 0 auto;
    }

    p {
      text-align: left;
    }

    .c-cover__description {
      text-align: left;

      span,
      p {
        font-family: $sans-serif !important;
        color: theme(default-txt);
      }
    }

    .c-cover__more {
      margin-top: 1rem;
    }
  }

  // Content
  .c-cover__content {
    padding: rem(16) rem(32) rem(24); // 16px 32px 24px 32px

    @include mq($from: S) {
      padding-top: 0;
      padding-bottom: rem(40); // 40px
    }

    @include mq($from: L) {
      padding-right: 0;
      padding-left: 0;
    }
  }

  // Title
  [class].c-cover__title {
    margin-bottom: 0;
    text-align: center;

    .c-cover--large & {
      color: inherit;
    }

    &::first-letter {
      text-transform: uppercase;
    }
  }

  // Description
  .c-cover__description {
    margin-top: rem(8); // 8px

    @include mq($from: S) {
      text-align: center;
    }

    @include mq($from: M) {
      max-width: rem(1218); // 1218px = 1074 + 72 * 2
      margin: rem(16) auto 0; // 16px auto 0 auto
    }

    @include mq($from: L) {
      @include fixed-width($item: 9);
    }
  }

  // Homepage cover
  .c-cover--large {
    position: relative; // Usefull to ::after
    background-color: transparent;
    color: theme(cover--image-txt);
    margin-bottom: rem(16); // 16px

    @include mq($from: S) {
      margin-bottom: rem(96); //96px
    }

    // Remove after Bastien's request (22/12/2022)
    // Background opacity
    // &::after {
    //   content: '';
    //   position: absolute;
    //   top: 0;
    //   right: 0;
    //   bottom: 0;
    //   left: 0;
    //   z-index: -1; // Push background under content
    //   background-color: transparentize(theme(cover--image__after-bgc), 0.75);
    // }

    // Content
    .c-cover__content {
      position: absolute;
      left: rem(16); // 16px
      width: calc(100% - #{rem(32)}); // 32px = 16 * 2
      max-width: rem(704); // 704px
      padding: 0;
      color: theme(cover--image-txt);
      bottom: 1rem;

      @include mq($from: S) {
        top: auto;
        bottom: 12.5%; // ~ 104px
        left: rem(50); // 50px
        width: calc(100% - #{rem(100)}); // 100px = 50 * 2
        text-align: left;
      }

      @include mq($from: L) {
        @include fixed-width($item: 6);
        // 3 weekds ago Bastien wanted the text to be moved from bottom to top,
        // then he want it back to the bottom
        // top: rem(16); // 16px
      }
    }

    // Title
    .c-cover__title {
      margin-bottom: rem(16); // 16px
      text-align: left;

      @include mq($from: S) {
        margin-bottom: rem(48); // 48px
      }
    }

    .c-cover__title a {
      #{$interactions} {
        color: inherit;
      }
    }

    // Illustration
    .c-cover__illustration {
      position: relative;
      z-index: -2;
    }
  }

  // Search page cover
  .c-cover--search .c-cover__content {
    padding-top: rem(24); // 24px
    padding-bottom: rem(24); // 24px

    @include mq($from: S) {
      padding-top: rem(40); // 40px
      padding-bottom: rem(80); // 80px
    }
  }

  .c-cover--search .c-cover__title {
    @extend %c-h3;
  }
}
