@if $toc {
  /* - Media____________________Place any image- and text-like content side-by-side */
} @else {
  /* Place any image- and text-like content side-by-side [object]
    ========================================================================== */

  // Grid
  .o-media {
    display: grid;
    grid-template-areas:
      'content'
      'illustration';

    $half: calc((100% - #{rem($grid-gutter)}) / 2); /* stylelint-disable-line */

    @include mq($from: S) {
      grid-gap: 0 rem($grid-gutter); // 24px
      grid-template-columns: $half $half;
      grid-template-areas: 'content illustration';
    }
  }

  .o-media--reverse {
    grid-template-areas:
      'illustration'
      'content';

    $half: calc((100% - #{rem($grid-gutter)}) / 2); /* stylelint-disable-line */

    @include mq($from: S) {
      grid-gap: 0 rem($grid-gutter); // 24px
      grid-template-columns: $half $half;
      grid-template-areas: 'illustration content';
    }
  }

  // Grid areas
  .o-media__illustration {
    grid-area: illustration;
  }

  .o-media__content {
    grid-area: content;
  }
}
