@if $toc {
  /* - Error____________________404 error */
} @else {
  /* 404 error [component]
  ========================================================================== */

  // Main
  .c-error {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    max-height: stretch;
  }

  // Error number
  .c-error__number {
    @include font($type: bold, $family: $serif);
    display: inline-block;
    margin-bottom: rem(8); // 8px
    color: theme(brand);
    font-size: rem(150); // 150px
    line-height: lh(150, 150); // 150px

    @include mq($from: S) {
      margin-bottom: rem(16); // 16px
    }
  }

  // Error title
  .c-error__title {
    @extend %c-h3;
    margin-bottom: rem(32); // 32px

    @include mq($from: S) {
      margin-bottom: rem(64); // 64px
    }
  }

  // Error image
  .c-error__illustration {
    width: rem(200); // 200px
    height: rem(200); // 200px
    margin-bottom: rem(32); // 32px

    @include mq($from: S) {
      display: inline-block;
      margin-right: rem(24); // 24px
    }
  }
}
