@if $toc {
  /* - Sale type________________Sale type */
} @else {
  /* Sale type [component]
    ========================================================================== */

  .c-sale-type {
    @include font($type: semibold, $family: $serif);
    position: absolute;
    top: rem(16); // 16px
    left: rem(16); // 16px
    z-index: 1; // Place the text above the slider
    letter-spacing: .5px;
    text-transform: capitalize;
    color: theme(sale-type-txt);
    background-color: theme(brand);

    @include mq($until: S) {
      padding: rem(4) rem(8); // 4px 8px
      font-size: rem(12); // 12px
      line-height: lh(18, 12); // 18px
    }

    @include mq($from: S) {
      padding: rem(4) rem(12) rem(8); // 4px 12px 8px 12px
      font-size: rem(14); // 14px
      line-height: lh(18, 14); // 18px
    }

    .c-slider--product & {
      top: rem(14); // 18px
      left: rem(16); // 20px
    }
  }


  // Context-specific positioning
  /*/ Display 2 items side by side on mobile
  .o-layout-wrap,
  .c-featured-product {
    @include mq($until: S) {
      .c-sale-type {
        top: rem(7); // 7px
        left: rem(8); // 8px
        font-size: rem(14); // 14px
        line-height: lh(18, 14); // 18px
      }
    }
  }

  .c-slider--opposite {
    @include mq($from: S) {
      .c-sale-type {
        font-size: rem(14); // 14px
        line-height: lh(18, 14); // 18px
      }
    }
  }*/
}
