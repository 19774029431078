@if $toc {
  /* - Heading__________________Heading (h1 -> h6) */
} @else {
  /* Heading [component]
   ========================================================================== */

  .c-h1,
  .c-h2,
  .c-h3,
  .c-h4 {
    @include font($type: medium, $family: $serif);
    margin: 0;
    letter-spacing: normal;
    color: theme(heading-txt);
  }

  .c-h1,
  .c-h2 {
    margin-bottom: rem(16); // 16px
  }

  .c-h3,
  .c-h4,
  .c-h5 {
    margin-bottom: rem(8); // 8px
  }

  .c-h1,
  %c-h1 {
    font-size: rem(34); // 34px
    line-height: lh(45, 34); // 45px
    font-weight: 600;

    @include mq($from: S, $until: M) {
      font-size: rem(52); // 52px
      line-height: lh(65, 52); // 65px
    }

    @include mq($from: M) {
      font-size: rem(70); // 70px
      line-height: lh(80, 70); // 80px
    }
  }

  .c-h2,
  %c-h2 {
    font-size: rem(24); // 24px
    line-height: lh(36, 24); // 36px

    @include mq($from: S) {
      font-size: rem(35); // 35px, designed 40px
      line-height: lh(46, 40); // 46px
    }
  }

  .c-h3,
  %c-h3 {
    font-size: rem(20); // 20px
    line-height: lh(30, 20); // 30px

    @include mq($from: S) {
      font-size: rem(30); // 30px
      line-height: lh(40, 30); // 40px
      letter-spacing: 1px;
    }
  }

  .c-h4,
  %c-h4 {
    font-size: rem(18); // 18px
    line-height: lh(26, 18); // 26px

    @include mq($from: S) {
      font-size: rem(24); // 24px
      line-height: lh(33, 24); // 33px
      letter-spacing: 0.8px;
    }
  }

  .c-h5 {
    @include font($type: regular, $family: $sans-serif);
    font-size: rem(16); // 16px
    line-height: lh(24, 16); // 24px

    @include mq($from: S) {
      font-size: rem(20); // 20px
      line-height: lh(28, 20); // 28px
      letter-spacing: 0.4px;
    }
  }

  .c-h6 {
    font-size: rem(16); // 16px
    line-height: lh(24, 16); // 24px

    @include mq($from: S) {
      font-size: rem(18); // 18px
      line-height: lh(26, 18); // 26px
      letter-spacing: 0.8px;
    }
  }

  .c-h7,
  %c-h7 {
    @include font($type: bold, $family: $sans-serif);
    font-size: rem(14); // 14px
    line-height: lh(21, 14); // 21px
    text-transform: uppercase;
  }

  // Variations
  .c-h3--light {
    color: theme(brand);
  }

  // With vertical margin
  .c-h3--space {
    margin: rem(16) 0; // 16px 0

    @include mq($from: S) {
      margin: rem(32) 0; // 32px 0
    }
  }

  // With opposite link
  .c-h3--opposite-link {
    @include mq($until: S) {
      margin-bottom: rem(16); // 16px
    }

    @include mq($from: S) {
      display: inline-block;
      margin-right: rem(24); // 24px
      vertical-align: middle;
      line-height: lh(48, 30); // 48px
    }
  }

  // Dark title
  .c-h4--dark {
    display: block;
    padding: rem(12) rem(16); // 12px 16px
    background-color: theme(h4--dark-bgc);

    @include mq($from: S) {
      padding: rem(16) rem(32); // 16px 32px
    }

    &:last-child {
      /* stylelint-disable-line */
      margin-bottom: 0;
    }
  }

  // Thick (and dark) title
  .c-h4--thick {
    margin-bottom: 0;

    @include mq($from: S) {
      padding: rem(28) rem(32); // 28px 32px
    }

    .c-h4__link {
      /* stylelint-disable-line */
      top: rem(28); // 28px

      @include mq($until: S) {
        position: static;
        display: block;
        margin-top: rem(4); // 4px
      }
    }
  }
}
