@if $toc {
  /* - Font-size________________Font-size */
} @else {
  /* Font-size [utility]
    ========================================================================== */

  [class].u-font-sm {
    font-size: rem(14); // 14px
    line-height: lh(24, 14); // 24px
  }

  [class].u-font-xs {
    font-size: rem(12); // 12px
    line-height: lh(18, 12); // 18px
  }
}
