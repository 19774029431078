@if $toc {
  /* - Block____________________Block with border and vertical space */
} @else {
  /* Block with border and vertical space [component]
    ========================================================================== */

  .c-border-block {
    padding: rem(16); // 16px
    border: 1px solid theme(default-bdc);

    @include mq($from: S) {
      padding-top: rem(48); // 48px
      padding-bottom: rem(48); // 48px
    }
  }
}
