@if $toc {
  /* - CMS______________________Content entered by WYSIWYG */
} @else {
  /* Content entered by WYSIWYG [component]
  ========================================================================== */

  // Scope root
  .s-cms {
    margin-top: rem(8); // 8px

    @include mq($from: S) {
      $max-width: 6 * $grid-col + 5 * $grid-gutter;
      max-width: rem($max-width); // 708px
      margin: rem(64) auto; // 64px auto
      margin-left: auto;
    }

    // Titles
    h1 {
      /* stylelint-disable-line */
      @extend %c-h3;

      @include mq($from: S) {
        margin-bottom: rem(32); // 32px
      }

      &:not(:first-child) {
        /* stylelint-disable-line */
        margin-top: rem(24); // 24px

        @include mq($from: S) {
          margin-top: rem(48); // 48px
        }
      }
    }

    h2 {
      @extend %c-h7;
      margin-top: rem(24); // 24px

      @include mq($until: S) {
        margin-top: rem(16); // 16px
        margin-bottom: rem(8); // 8px
      }
    }

    // Paragraphs
    p {
      margin-bottom: rem(8); // 8px

      @include mq($from: S) {
        margin-bottom: rem(16); // 16px
      }
    }

    // Lists
    ol,
    ul {
      margin: 0 0 rem(16); // 0 0 16px 0
    }

    ol {
      padding-left: rem(18); // 16px
    }

    ul {
      padding-left: 0;
    }

    li {
      margin-bottom: rem(8); // 8px
    }

    ul > li {
      padding-left: rem(16); // 16px
      list-style: none;
      background: url('../../../svg/icon/bullet-point.svg')
        #{rem(2)}
        #{rem(8)} /
        #{rem(5)}
        #{rem(5)}
        no-repeat;
    }
  }
}
