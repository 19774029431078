@if $toc {
  /* - Page_____________________Classes applied to body and html */
} @else {
  /* Classes applied to body and html [component]
    ========================================================================== */

  /**
  * [1] : Required for iOS
  * @see [overflow-y:hidden; iOS issue with inner scrolling div](https://stackoverflow.com/questions/17308027/overflow-yhidden-ios-issue-with-inner-scrolling-div)
  */

  // HTML Class
  // Class added when mobile navigation is displayed
  .has-unfolded-header {
    &,
    body {
      height: 100%; // [1]
      overflow-y: hidden;
    }

    main,
    aside,
    footer {
      display: none; // [1]
    }
  }

  // Body Class
  // Targets touch type terminals and non-touch terminals that have a width of less than 50em and IE11 under 50em
  @media (hover: none),
    (hover: hover) and (max-width: 49.99em),
    (-ms-high-contrast: none) and (max-width: 49.99em) {
    .has-no-footer {
      padding-bottom: 0;
    }
  }


  @include mq($until: S) {
    .has-sticky-bar {
      padding-bottom: rem(108); // 108px (= 60px + 48px : c-shortcut + c-price--sticky's height)
    }

    .has-sticky-bars {
      padding-bottom: rem(156); // 156px (= 60px + 48px + 48px : c-shortcut + c-price--sticky + c-btn--sticky-engraving's height)
    }

    .has-sticky-basket-summary {
      padding-top: rem(56); // 56px (= c-disclosure--basket-summary's height)
      padding-bottom: 0;
    }

    .has-sticky-basket-summary.has-sticky-bar {
      padding-bottom: rem(64); // 64px (= c-form__next-step & c-form__pay's height)
    }
  }

  // Targets touch type terminals that have a width greater than or equal to 50em and IE11 below 50em
  @media (hover: none) and (min-width: 50em),
    (-ms-high-contrast: none) and (min-width: 50em) {
    .has-sticky-basket-summary.has-sticky-bar {
      padding-bottom: 0;
    }
  }
}
