@if $toc {
  /* - Shortcut_________________Shortcut menu in site header */
} @else {
  /* Shortcut menu in site header [component]
    ========================================================================== */

  // Nav tag
  .c-shortcut {
    position: relative; // Push content over .c-header__contact
    z-index: 1; // Push content over .c-header__contact

    // Targets touch type terminals, non-touch terminals that have a width of less than 50em and IE11 under 50em
    @media (hover: none),
      (hover: hover) and (max-width: 49.99em),
      (-ms-high-contrast: none) and (max-width: 49.99em) {
      position: fixed;
      right: 0;
      bottom: 0;
      left: 0;
      z-index: 666;
      padding: rem(8) rem(16); // 8px 16px
      background-color: theme(shortcut-bgc);
      border-top: 1px solid theme(shortcut-bgc);
    }
  }


  // List
  .c-shortcut__list {
    // Targets touch type terminals, non-touch terminals that have a width of less than 50em and IE11 under 50em
    @media (hover: none),
      (hover: hover) and (max-width: 49.99em),
      (-ms-high-contrast: none) and (max-width: 49.99em) {
      display: flex;
      justify-content: space-between;
    }
  }


  // List items
  .c-shortcut__item:not(:nth-child(2)) {
    // Targets non-touch terminals that have a width greater than or equal to 50em and IE11 below 50em
    @media (hover: hover) and (min-width: 50em),
      (-ms-high-contrast: none) and (min-width: 50em) {
      margin-left: rem(16); // 16px
    }

    // Targets non-touch terminals that have a width greater than or equal to 67.75em and IE11 below 67.75em
    @media (hover: hover) and (min-width: 67.75em),
      (-ms-high-contrast: none) and (min-width: 67.75em) {
      margin-left: rem(40); // 40px
    }
  }

  // Menu item
  .c-shortcut__item--menu {
    // Targets non-touch terminals that have a width greater than or equal to 50em and IE11 below 50em
    @media (hover: hover) and (min-width: 50em),
      (-ms-high-contrast: none) and (min-width: 50em) {
      display: none;
    }
  }


  // Links
  .c-shortcut__link {
    @include unstyled-button($extend: true, $important: false);
    color: theme(default-txt);
    font-weight: normal;
    font-size: rem(12); // 12px
    line-height: 1;
    text-align: center;

    #{$interactions},
    &[aria-current] {
      color: theme(brand);
      text-decoration: none;
      outline: 0;
    }

    // Targets non-touch terminals that have a width greater than or equal to 50em and IE11 below 50em
    @media (hover: hover) and (min-width: 50em),
      (-ms-high-contrast: none) and (min-width: 50em) {
      text-align: left;
    }
  }

  // Active page
  .c-shortcut__link[aria-current="true"] {
    color: theme(brand);
  }

  // Cart link
  .c-shortcut__link--cart {
    position: relative;
  }


  // Number of items in the cart
  .c-shortcut__product {
    position: absolute;
    top: rem(-6); // -6px
    right: rem(-6); // -6px
    width: rem(22); // 22px
    height: rem(22); // 22px
    color: theme(shortcut-product-txt);
    font-weight: normal;
    font-size: rem(10); // 10px
    line-height: lh(23, 10); // 23px
    letter-spacing: 0;
    text-align: center;
    background-color: theme(brand);
    border-radius: 50%;

    // Targets non-touch terminals that have a width greater than or equal to 50em and IE11 below 50em
    @media (hover: hover) and (min-width: 50em),
      (-ms-high-contrast: none) and (min-width: 50em) {
        position: relative;
        right: auto;
        left: rem(4); // 4px
        top: rem(-2); // -2px
        display: inline-block;
    }
  }


  // SVG icons
  .c-shortcut__icon {
    display: block;
    width: rem(24); // 24px
    height: rem(24); // 24px
    margin: 0 auto rem(4); // 0 auto 4px auto

    // Targets non-touch terminals that have a width greater than or equal to 50em and IE11 below 50em
    @media (hover: hover) and (min-width: 50em),
      (-ms-high-contrast: none) and (min-width: 50em) {
      display: inline-block;
      vertical-align: rem(-7); // -7px
      margin: 0 rem(8) 0 0; // 0 8px 0 0
    }
  }
}
