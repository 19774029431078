@if $toc {
  /* - Loyalty score____________Loyalty score */
} @else {
  /* Loyalty score [component]
    ========================================================================== */

  // Root element
  .c-loyalty-score {
    margin-bottom: rem(16); // 16px
    padding: rem(16); // 16px
    font-size: rem(14); // 14px
    line-height: lh(21, 14); // 21px
    background-color: theme(loyalty-score-bgc);

    @include mq($from: S) {
      margin-bottom: rem(32); // 32px
      $padding: $grid-col + $grid-gutter;  /* stylelint-disable-line */
      padding: rem(32) rem($padding); // 32px 122px
    }
  }


  // Number of points & discount granted
  .c-loyalty-score__sum {
    display: block;
    font-size: rem(24); // 24px
    line-height: lh(21, 14); // 21px

    @include mq($from: S) {
      margin-top: rem(16); // 16px
    }
  }


  .c-loyalty-score .o-layout__item:first-child {
    @include mq($until: S) {
      margin-bottom: rem(8); // 8px
    }
  }
}

