@if $toc {
  /* - Font-weight________________Font-weight */
} @else {
  /* Font-weight [utility]
    ========================================================================== */

  [class].u-strong {
    font-family: $sans-serif;
    font-weight: 700;
    font-size: rem(14); // 14px
    line-height: lh(24, 14); // 24px
  }

}
