@if $toc {
  /* - Buttons__________________Buttons */
} @else {
  /* Buttons [component]
    ========================================================================== */

  /**
  * @see  unstyled-button` mixin is defined in `./source/assets/styles/scss/tools/_tools.unstyled-button.scss`
  */

  // Common base
  .c-btn {
    @include unstyled-button($extend: true, $important: false);
    display: inline-block;
    padding: rem(11) rem(32); // 11px 32px
    font-size: rem(14); // 14px
    line-height: lh(24, 14); // 24px
    font-weight: normal;
    letter-spacing: inherit;
    border-width: 1px;
    border-style: solid;
    transition: all var(--transition-duration);
    width: auto;

    #{$interactions} {
      text-decoration: none;
    }
  }

  // Side by side buttons
  .c-btn + .c-btn {
    margin-left: rem(12); // 12px
  }

  // Buttons button next to .c-h3--opposite-link
  .c-h3--opposite-link + .c-btn {
    @include mq($until: S) {
      margin-bottom: rem(16); // 16px
    }
  }

  // Primary, Secondary, Tertiary, Light, Ghost, Filter
  $list: (
    'primary',
    'secondary',
    'tertiary',
    'light',
    'ghost',
    'filter'
  ); /* stylelint-disable-line */
  @each $type in $list {
    .c-btn--#{$type},
    %c-btn--#{$type} {
      color: theme(btn--#{$type}-txt);
      background-color: theme(btn--#{$type}-bgc);
      border-color: theme(btn--#{$type}-bdc);

      #{$interactions} {
        color: theme(btn--#{$type}-txt_hover);
        background-color: theme(btn--#{$type}-bgc_hover);
        border-color: theme(btn--#{$type}-bdc_hover);
      }
    }
  }

  // Fill
  .c-btn--fill {
    color: theme(btn--fill-txt);
    background-color: theme(btn--fill-bgc);
    border-color: theme(btn--fill-bdc);

    #{$interactions} {
      color: theme(btn--fill-txt_hover);
    }
  }

  // Thin (button without horizontal padding)
  .c-btn--thin {
    padding-right: 0;
    padding-left: 0;
  }

  // Light
  .c-btn--light {
    padding: rem(4) rem(32) rem(2); // 4px 32px 2px
    text-transform: uppercase;
    font-size: rem(12); // 12px
    line-height: lh(24, 12); // 24px
  }

  // Pay
  .c-btn--pay {
    @extend %c-btn--tertiary;
    width: 100%;
    padding-right: rem(56); // 56px
    padding-left: rem(16); // 16px
    text-align: center;
    background-image: url('../../../svg/icon/pay.svg');
    background-repeat: no-repeat;
    background-position: right #{rem(12)} top 50%;
    background-size: #{rem(32)} #{rem(32)};
  }

  // Back
  .c-btn--back {
    @extend %c-btn--secondary;
    padding-right: rem(22); // 22px
    padding-left: rem(22); // 22px
  }

  // New - Print
  .c-btn--new,
  .c-btn--print {
    padding-top: rem(7); // 7px
    padding-bottom: rem(7); // 7px
    line-height: lh(32, 14); // 32px
  }

  // New
  .c-btn--new {
    @extend %c-btn--secondary;
    padding-right: rem(18); // 18px
    padding-left: rem(22); // 22px
  }

  // Filter
  .c-btn--filter {
    vertical-align: top;
    padding: rem(10) rem(16); // 10px 16px
    line-height: lh(21, 14); // 21px
    text-align: left;
    border-color: theme(btn--filter-bdc);

    @include mq($from: S) {
      margin-left: rem(24); // 24px
      text-align: left;
    }
  }

  // Print
  .c-btn--print {
    padding-right: rem(22); // 22px
    padding-left: rem(18); // 18px
    color: theme(btn--print-txt);
    background-color: theme(btn--print-bgc);
    border-color: theme(btn--print-bdc);

    #{$interactions} {
      color: theme(btn--print-txt_hover);
    }

    &:focus {
      text-decoration: underline;
    }
  }

  // Icons
  .c-btn__icon {
    position: relative;
    bottom: rem(3); // 3px
    width: rem(28); // 28px
    height: rem(28); // 28px

    .c-btn--back & {
      width: rem(10); // 10px
      height: rem(10); // 10px
      margin-right: rem(12); // 12px
      bottom: 0;
    }

    .c-btn--new & {
      margin-left: rem(12); // 12px
    }

    .c-btn--filter & {
      margin-right: rem(8); // 8px
      width: rem(24); // 24px
      height: rem(24); // 24px
    }

    .c-btn--print & {
      margin-right: rem(12); // 12px
    }
  }

  // Disabled
  [disabled] {
    // Default
    &.c-btn {
      cursor: default;
      opacity: 0.4;
    }

    // Background & border
    $listBis: (
      'primary',
      'secondary',
      'tertiary',
      'light',
      'ghost'
    ); /* stylelint-disable-line */
    @each $type in $listBis {
      &.c-btn--#{$type} {
        #{$interactions} {
          background-color: theme(btn--#{$type}-bgc);
          border-color: theme(btn--#{$type}-bdc);
        }
      }
    }

    // Text
    $listTer: (
      'secondary',
      'light',
      'fill',
      'ghost'
    ); /* stylelint-disable-line */
    @each $type in $listTer {
      &.c-btn--#{$type} {
        #{$interactions} {
          color: theme(btn--#{$type}-txt);
        }
      }
    }
  }

  // Buttons in modals
  .center-modal__wrapper .c-btn {
    @include mq($until: S) {
      margin: rem(16) rem(8) 0; // 16px 8px 0 8px
    }

    @include mq($from: S) {
      margin: rem(32) rem(12) 0; // 32px 12px 0 12px
    }
  }

  // Sticky buttons on mobile
  // @TODO: Update position
  @include mq($until: S) {
    .c-btn--sticky-cart,
    .c-btn--sticky-engraving {
      position: fixed;
      right: 0;
      z-index: 666;
      padding-right: 0;
      padding-left: 0;
    }

    // Add to cart
    .c-btn--sticky-cart {
      bottom: rem(60); // 60px (=c-shortcut's height)
      width: calc(50% + #{rem(16)});
    }

    [class].c-btn.c-btn--sticky-cart {
      margin-bottom: 0;
    }

    // Add an engraving
    .c-btn--sticky-engraving {
      bottom: 6.65rem;
      width: 100%;
      background-color: theme(btn--sticky-engraving-bgc);
    }
  }

  // Specific case
  // Button on label tag
  label.c-btn {
    /* stylelint-disable-line */
    margin-bottom: 0;

    input:checked + & {
      color: theme(btn--tertiary-txt);
      background-color: theme(btn--tertiary-bgc);
      border-color: theme(btn--tertiary-bdc);
    }
  }
}
