@if $toc {
  /* - Layout___________________Generic layout */
} @else {
  /* Generic layout [object]
    ========================================================================== */

  /**
  * @note Mixins are defined in tool.width file
  */

  // Layout
  @include mq($from: S) {
    .o-layout {
      display: flex;
      justify-content: space-between;
      width: 100%;
    }

    // Layout modifiers
    .o-layout--start-x {
      justify-content: start;
    }

    .o-layout--center-x {
      justify-content: center;
    }

    .o-layout--end-x {
      justify-content: end;
    }

    .o-layout--start-y {
      align-items: flex-start;
    }

    .o-layout--center-y {
      align-items: center;
    }

    .o-layout--end-y {
      align-items: flex-end;
    }

    .o-layout--row-reverse {
      flex-direction: row-reverse;
    }

    .o-layout--wrap {
      flex-wrap: wrap;
    }
  }

  // Items
  @include mq($from: S) {
    $column: (2, 3, 4, 5, 6, 7, 8, 9, 12);
    $item: (2, 4);
    $no-space: (4);

    .o-layout__item {
      // Calculated width in rem
      @each $number in $column {
        &-#{$number}-cols {
          @include fixed-width($item: $number);
        }
      }

      // Calculated width in percent
      @each $number in $item {
        .o-layout--#{$number}-items & {
          @include equal-width($items: $number);
        }
      }

      // Calculated width in percent without internal gutter
      @each $number in $no-space {
        .o-layout--no-space.o-layout--#{$number}-items & {
          @include equal-width($items: $number, $gutter-width: 0);
        }
      }
    }

    // Default space
    [class*='o-layout__item'] {
      &:not(:last-child) {
        margin-right: rem($grid-gutter); // 24px
      }

      // Without space
      .o-layout--no-space & {
        margin-right: 0;
      }
    }

    // Specific case
    // Newsletter bloc
    .o-layout__item-four-tenths {
      @include ratio-width(4, 10);
    }

    .o-layout__item-six-tenths {
      @include ratio-width(6, 10);
    }

    // Wrap with multiple width
    .o-layout--wrap {
      .o-layout__item {
        margin-right: 0;
      }

      .o-layout__item--half {
        $gutter: calc($grid-gutter / 2);
        width: calc(50% - #{rem($gutter)});
      }

      .o-layout__item--full {
        width: 100%;
      }
    }
  }

  // Shrink
  .o-layout__shrink-2 {
    /* stylelint-disable-line */
    flex-shrink: 2;
  }

  .o-layout__no-shrink {
    flex-shrink: 0;
  }

  // Responsive class
  @include mq($until: S) {
    .o-layout--keep\@us {
      &.o-layout {
        display: flex;
        justify-content: space-between;
        width: 100%;
      }

      &.o-layout--start-x {
        justify-content: start;
      }

      &.o-layout--center-x {
        justify-content: center;
      }
    }

    .o-layout\@us {
      display: flex;
      justify-content: space-between;
      width: 100%;
    }
  }

  // Specific case
  // Wrap layout
  .o-layout-wrap {
    /* stylelint-disable-line */
    display: flex;
    flex-wrap: wrap;
    width: 100%;
  }

  .o-layout-wrap .o-layout__item {
    @include mq($until: S) {
      @include layout-wrap($number: 2, $gutter: $grid-gutter--us);

      margin-right: rem($grid-gutter--us); // 16px
    }
  }

  // 2 items per line
  .o-layout-wrap--2-items .o-layout__item {
    @include mq($from: S) {
      @include layout-wrap($number: 2);
    }
  }

  // 3 items per line
  .o-layout-wrap--3-items .o-layout__item {
    @include mq($from: S, $until: M) {
      @include layout-wrap($number: 2);
    }

    @include mq($from: M) {
      @include layout-wrap($number: 3);
    }
  }

  // 4 items per line
  // 1 item per line under S breakpoint
  .o-layout-wrap--4-items .o-layout__item,
  .o-layout-wrap--1-item\@us .o-layout__item {
    @include mq($until: S) {
      width: 100%;
      margin-right: 0;

      &:not(:first-child) {
        margin-top: rem(16); // 16px
      }
    }
  }

  .o-layout-wrap--4-items .o-layout__item {
    @include mq($from: S, $until: M) {
      @include layout-wrap($number: 3);
    }

    @include mq($from: M) {
      @include layout-wrap($number: 4);
    }
  }

  // Specific case
  // Reverse layout
  .o-layout-reverse {
    display: flex;
    width: 100%;

    @include mq($until: S) {
      flex-direction: column-reverse;
    }

    @include mq($from: S) {
      flex-direction: row-reverse;
    }
  }

  // Reverse order
  .o-layout-reverse {
    > .o-layout--2-items {
      @include equal-width($items: 2);
    }

    > .o-layout__item {
      margin-right: 0;

      @include mq($from: S) {
        &:not(:first-child) {
          margin-right: rem($grid-gutter); // 24px
        }
      }
    }
  }
}
