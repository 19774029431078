@if $toc {
  /* - Featured product_________Featured products */
} @else {
  /* Featured products [component]
    ========================================================================== */

  [class].c-featured-product .o-layout {
    @include mq($until: S) {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
    }
  }


  // Title
  .c-featured-product__title {
    @extend %c-h4;
    margin-bottom: rem(16); // 16px
    text-align: center;

    @include mq($from: S) {
      margin-bottom: rem(32); // 32px
    }
  }


  // Items
  @include mq($until: S) {
    .c-featured-product__item {
      width: calc(50% - #{rem(8)});

      &:nth-child(odd) {
        margin-right: 0;
      }

      &:not(:nth-child(-n+2)) {
        margin-top: rem(28); // 28px
      }
    }
  }
}
