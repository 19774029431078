@if $toc {
  /* - Rating___________________Rating system */
} @else {
  /* Rating system [component]
    ========================================================================== */

  .c-rating {
    display: flex;
    align-items: center;
    margin-bottom: rem(8); // 8px
  }

  // Stars
  .c-rating__stars {
    position: relative;
    min-height: rem(24); // 24px
    padding-left: rem(108); // 108px

    // Background: empty and full stars
    &::before,
    &::after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      height: rem(20); // 20px
      background-repeat: repeat-x;
      background-position: 0 50%;
      background-size: rem(20) auto; // 20px
    }

    // Empty stars
    &::before {
      width: rem(100); // 100px
      background-image: url('../../../../svg/icon/rating-off.svg');
    }

    // Full stars
    &::after {
      background-image: url('../../../../svg/icon/rating-on.svg');
    }
  }

  $number: (1, 2, 3, 4); /* stylelint-disable-line */
  @each $integer in $number {
    @for $decimal from 0 through 9 {
      .c-rating__stars--#{$integer}#{$decimal}::after {
        $width: 20 * $integer + 20 * calc($decimal / 10);
        width: rem($width);
      }
    }
  }

  .c-rating__stars--50::after {
    width: rem(100); // 100px
  }

  // Number of user reviews
  .c-rating__review {
    display: inline-block;
    margin-right: rem(8); // 8px

    &::before {
      content: '(';
    }

    &::after {
      content: ')';
    }
  }

  /* Product ratings / review messages */

  .c-rating-view {
    margin-bottom: rem(64); // 64px
    font-size: rem(14); // 1px
    line-height: lh(21, 14); // 21px

    @include mq($from: S) {
      margin-bottom: rem(96); // 96px
    }
  }

  // Container
  .c-rating-view__container {
    margin: rem(16) 0 rem(32); // 16px 0 32px 0
    padding-top: rem(16); // 16px

    @include mq($from: S) {
      margin-top: rem(24); // 24px
      $padding: $grid-col + $grid-gutter; /* stylelint-disable-line */
      padding: rem(32) rem($padding) 0; // 32px 122px 0 122px
    }

    &:not(:first-child) {
      border-top: 1px solid theme(default-bdc);
    }

    p:not(:last-of-type) {
      margin-bottom: rem(8);
    }
  }

  // Message subject
  .c-rating-view__subject {
    display: block;
    margin-top: rem(8); // 8px
    font-weight: 600;
  }

  // Message content
  .c-rating-view__content {
    display: block;
    padding: rem(16) 0; // 16px
  }

  // Average rating
  .c-rating-average__title {
    display: flex;
    gap: 1rem;
  }

  .c-rating-average__based-upon {
    font-style: italic;
  }

  .c-rating-answer {
    margin-left: 0;
    padding: 0.5rem;
    background-color: theme(default-bdc);

    .c-rating-answer__author {
      font-weight: bold;
      margin-top: 1rem;
      color: theme(message--help-txt);
    }

    @include mq($from: S) {
      background-color: theme(message--help-bgc);
      margin-left: 2rem;
      text-align: right;
      padding: 1.5rem;
    }
  }
}
