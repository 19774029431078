@if $toc {
  /* - Address____________________Address block */
} @else {
  /* Address block [component]
    ========================================================================== */

  // Container
  .c-address {
    padding: rem(16); // 16px
    border: 1px solid theme(default-bdc);

    @include mq($from: S) {
      display: flex;
      flex-direction: column;
      padding: rem(24); // 24px
    }

    // Delete action is not a button but a link
    .c-btn--fill {
      font-weight: normal;
    }
  }

  // Details (number, street & city)
  .c-address__details {
    font-size: rem(14); // 14px
    line-height: lh(21, 14); // 21px
    font-weight: 700;
    text-transform: uppercase;

    @include mq($from: S) {
      margin-bottom: rem(24); // 24px
    }
  }

  // Delete & modify buttons
  .c-address__action {
    text-align: center;

    @include mq($from: S) {
      margin-top: auto;
      text-align: right;
    }
  }

  // Specific case
  // Adresse in cart total
  .c-cart-total .c-address {
    border: 0;
    padding: 0;
    line-height: lh(24, 14); // 24px
  }
}
