@if $toc {
  /* - Price____________________Price display */
} @else {
  /* Price display [component]
    ========================================================================== */

  .c-price {
    display: inline-block;
    font-size: rem(18); // 18px
    line-height: lh(21, 18); // 21px

    @include mq($from: S) {
      font-size: rem(20); // 20px
      line-height: lh(21, 20); // 21px
    }
  }


  // Reset <ins> default styles
  .c-price ins {
    color: inherit;
  }


  // From
  .c-price__from {
    @include mq($until: S) {
      font-size: rem(16); // 16px
      line-height: lh(42, 16); // 40px
    }

    @include mq($from: S) {
      font-size: em(18, 24); // 18px
      line-height: lh(40, 18); // 40px
    }
  }


  // Sticky price on mobile
  // @TODO: Update position
  @include mq($from: S) {
    .c-price--sticky {
      display: none;
    }
  }

  @include mq($until: S) {
    .c-price--sticky {
      position: fixed;
      bottom: rem(60); // 60px (= c-shortcut's height)
      left: 0;
      z-index: 666;
      width: calc(50% - #{rem(16)});
      padding-left: rem(16); // 16px
      line-height: rem(27); // 27px
      background-color: theme(default-bgc);

      .c-price__from {
        @include font($type: regular, $family: $defaut-font-family);
        display: block;
        padding: em(7, 14) 0 em(3, 14); // 7px 0 3px
        font-size: em(14, 20); // 14px
        line-height: 1; // 14px
      }

      .c-price__amount:first-child {
        line-height: rem(48); // 48px
      }
    }
  }
}
