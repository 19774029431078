@if $toc {
  /* - Feature__________________Feature bloc */
} @else {
  /* Feature bloc [component]
    ========================================================================== */

  // List
  [class].c-feature {
    padding: rem(24) rem(16); // 24px 16px

    @include mq($from: S) {
      padding: rem(48) rem(24); // 48px 24px
    }

    @include mq($from: L) {
      padding-right: 0;
      padding-left: 0;
    }
  }

  // Items
  .c-feature__item {
    background-repeat: no-repeat;
    background-size: auto rem(40); // 40px

    @include mq($until: S) {
      margin-bottom: rem(24); // 24px
      padding: rem(8) 0 rem(8) rem(56); // 8px 0 8px 56px
      background-position: 0 50%;
    }

    @include mq($from: S) {
      padding-top: rem(56); // 56px
      text-align: center;
      background-position: 50% 0;
    }
  }

  /* stylelint-disable */
  $list: (
    'diamond',
    'ethical-gold',
    'gold-18ct',
    'gold-9ct',
    'silver',
    'stainless-steel',
    'silvery-metal',
    'metal',
    'wood',
    'guarantee',
    'certificate',
    'production',
    'engraving',
    'guide'
  );
  /* stylelint-enable */

  @each $modifier in $list {
    .c-feature__item--#{$modifier} {
      background-image: url('../../../../svg/icon/#{$modifier}.svg');
    }
  }

  // Content
  .c-feature__content {
    /* stylelint-disable-line */
    @include font($type: semibold, $family: $sans-serif);
    font-size: rem(14); // 14px
    line-height: lh(18, 14); // 18px
    color: theme(default-txt);
  }
}
